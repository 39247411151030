import React, { useState, useContext, useEffect } from 'react';
import { RiShoppingCart2Fill } from "react-icons/ri";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, useSearchParams } from 'react-router-dom';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import { useMediaQuery } from "react-responsive";
import ClipLoader from "react-spinners/ClipLoader";
import Sheet from 'react-modal-sheet';
import { useSelector, useDispatch } from 'react-redux';
import CartAction from '../../redux/actions/CartAction';
import logo from '../../images/bg_image.webp';
const ProductCard = React.memo(({ ele }) => {
    const dispatch = useDispatch()
    // const [carousel, setCarousel] = useState(0);
    const [percentage, setpercentage] = useState(undefined);
    const [selling_price, setselling_price] = useState(undefined);
    const [mrp, setmrp] = useState(undefined);


    const selected_variant = useSelector(state => state.catlogdetail.selected_variant);
    const masterData = useSelector(state => state.masterdata.masterData);
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const product_id_variant = useSelector(state => state.catlogdetail.product_id_variant);
    const mobilevariants = useSelector(state => state.catlogdetail.mobilevariants);
    const hasMore = useSelector(state => state.catlogdetail.hasMore);

    const [productLoaderstatus, setproductLoaderstatus] = useState(false);

    const { addToCartProduct, changeCartQuantitydup } = CartAction()


    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    // const { setselected_variant, masterData, cartDetail, selected_variant, product_id_variant, setproduct_id_variant, setmobilevariants, mobilevariants, setcurrent_variant, authStatus } = useContext(StateContext);

    const [searchParams, setSearchParams] = useSearchParams();

    const handleclick = (item) => {
    }
    const handleclickItem = (item) => {
        dispatch({ type: 'setselected_variant', payload: item })
    }

    const updateQuantity = (quantity, cart_id) => {
        if (quantity > 0) {
            setproductLoaderstatus(true)
            const input_data = {
                cart_id: cart_id,
                quantity: quantity
            }
            dispatch(changeCartQuantitydup(input_data, setproductLoaderstatus, productLoaderstatus))
        }
    }

    const selected = (eventKey) => {
        dispatch({ type: 'setcurrent_variant', payload: eventKey.split("@")[0] })


        if (selected_variant.filter(ele1 => ele1.productid == ele.productid).length <= 0) {
            dispatch({
                type: 'setselected_variant', payload: [
                    ...selected_variant,
                    {
                        productid: ele.productid,
                        variantid: eventKey.split("@")[1],
                        variantname: eventKey.split("@")[0]
                    }
                ]
            })


        } else {
            const replaceObjectById = (array, idToFind, newObject) => {
                return array.map((obj) => (obj.productid === idToFind ? newObject : obj));
            };

            // Example: Find object with id 1 and replace it
            const newObject = {
                productid: ele.productid,
                variantid: eventKey.split("@")[1],
                variantname: eventKey.split("@")[0]
            };

            var arrayOfObjects = replaceObjectById(selected_variant, ele.productid, newObject);
            dispatch({ type: 'setselected_variant', payload: arrayOfObjects })

        }
    };

    const openmobilevariants = () => {
        dispatch({ type: 'setmobilevariants', payload: true })
        dispatch({ type: 'setproduct_id_variant', payload: ele.productid })
    };


    const handlemobileClick = (variantid, variantname) => {
        if (selected_variant.filter(ele1 => ele1.productid == ele.productid).length <= 0) {
            dispatch({
                type: 'setselected_variant', payload: [
                    ...selected_variant,
                    {
                        productid: ele.productid,
                        variantid: variantid,
                        variantname: variantname
                    }
                ]
            })
        } else {
            const replaceObjectById = (array, idToFind, newObject) => {
                return array.map((obj) => (obj.productid === idToFind ? newObject : obj));
            };

            // Example: Find object with id 1 and replace it
            const newObject = {
                productid: ele.productid,
                variantid: variantid,
                variantname: variantname
            };

            var arrayOfObjects = replaceObjectById(selected_variant, ele.productid, newObject);
            dispatch({ type: 'setproduct_id_variant', payload: arrayOfObjects })

        }

        dispatch({ type: 'setmobilevariants', payload: false })

        dispatch({ type: 'setproduct_id_variant', payload: ele.productid })

    };

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleToggle = (isOpen) => {
        setDropdownOpen(isOpen);
    };

    useEffect(() => {
        if (selected_variant.filter(ele1 => ele1.productid == ele.productid).length > 0) {
            var dt = selected_variant.filter(ele1 => ele1.productid == ele.productid);
            var io1 = ele.variantList.filter(ele1 => ele1.variantid == dt[0].variantid)
            if (io1.length > 0) {
                var io = io1[0]
                var price = io.mrp;
                var diff = price - io.selling_price;
                var calpercentage = diff / price * 100;
                setpercentage(Math.round(calpercentage))
                setmrp(io.mrp);
                setselling_price(io.selling_price);

            }
        }

    }, [selected_variant])

    const caretIcon = isDropdownOpen ? (
        // Caret icon for open state (caret up)
        <IoChevronUp className='varinat-caret-icon' />
    ) : (
        // Caret icon for closed state (caret down)
        <IoChevronDown className='varinat-caret-icon' />
    );

    const add2cartfun = (item) => {
        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (localStorage.getItem("sessionId") === null) {
                localStorage.setItem('sessionId', randomId);
            }
        }
        const input_data11 = {
            "product_id": item.productid,
            "quantity": 1,
            "type": 'product',
            ...(authStatus == false && { "session_id": localStorage.getItem("sessionId") }),
            ...(item.variantList.length > 0 && { "variantid": selected_variant.filter(ele1 => ele1.productid == ele.productid).length > 0 ? selected_variant.filter(ele1 => ele1.productid == ele.productid)[0].variantid : item.variantList[0].variantid }),
        }

        dispatch(addToCartProduct(input_data11, setproductLoaderstatus, productLoaderstatus))
    }

    if (ele.variantList.length > 0) {
        var detail_url = `${ele.slug}?variant=${ele.variantList[0].variantid}`;
    } else {
        var detail_url = ele.slug;
    }



    useEffect(() => {
        var price = ele.mrp;
        var diff = price - ele.selling_price;
        var calpercentage = diff / price * 100;
        setpercentage(Math.round(calpercentage))
        setselling_price(ele.selling_price)
        setmrp(price)
    }, [ele])
    return (
        <>
            {
                (percentage != undefined && mrp != undefined && selling_price != undefined) &&
                <>
                    <div className="product-grid">
                        <NavLink className="text-decoration-none" to={`/product/${detail_url}`}>
                            <div className="product-image" >
                                <div className="image">
                                    <img referrerPolicy="no-referrer" className="img-1" src={ele.product_images.length > 0 && ele.product_images[0]}
                                        onError={e => {
                                            e.currentTarget.src = logo
                                        }} />
                                </div>
                            </div>
                        </NavLink>
                        <div className="product-content">
                            <h1 className='product-brand'>{ele.brand_name}</h1>
                            <NavLink className="text-decoration-none" to={`/product/${detail_url}`}>
                                <h3 className="title">{ele.product_title}</h3>
                            </NavLink>

                            {
                                ele.variantList.length > 0 &&

                                    ele.variantList.length > 1 ?

                                    !isDesktoporMobile ?
                                        <>
                                            <div className='product-card-variant-dropdown'>
                                                <NavDropdown id="nav-dropdown-search"
                                                    onToggle={handleToggle}
                                                    title={
                                                        <>
                                                            {selected_variant.filter(vari => vari.productid == ele.productid).length > 0 && selected_variant.filter(vari => vari.productid == ele.productid)[0].variantname || ele.variantList[0].variantname}

                                                            {caretIcon}
                                                        </>
                                                    }
                                                    className='product-card-selected-variant' onSelect={selected}>
                                                    <div className='product-card-variant-dropdownlist'>
                                                        {
                                                            ele.variantList.map((ele1, index) => {

                                                                var price = ele1.mrp;
                                                                var diff = price - ele1.selling_price;
                                                                var calpercentage = diff / price * 100;

                                                                return (
                                                                    <NavDropdown.Item className={`product-card-variant-listitem 
                                                    ${selected_variant.filter(vari => vari.productid == ele.productid).length > 0 ? (selected_variant.filter(vari => vari.productid == ele1.productid)[0].variantid == ele1.variantid ? "selected_variant_active" : " ") : (index == 0) && "selected_variant_active"}`}
                                                                        key={index} eventKey={`${ele1.variantname}@${ele1.variantid}`}>
                                                                        <div className='variant_title'>{ele1.variantname}</div>
                                                                        <div className='product-price'>
                                                                            <div className="product-price product-price-revamp">
                                                                                <h5 className="Paragraph-16-M--Semibold">{localStorage.getItem("currency") + ele1.selling_price}</h5>
                                                                                {
                                                                                    ele1.mrp != ele1.selling_price &&
                                                                                    <h5 className="offer offer-revamp Paragraph-16-M--Regular">{localStorage.getItem("currency") + ele1.mrp}</h5>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                ele.mrp != ele.selling_price &&
                                                                                <h5 className="offer-label-revamp Paragraph-12-XS--Semibold">{Math.round(calpercentage)}% OFF</h5>
                                                                            }
                                                                        </div>
                                                                    </NavDropdown.Item>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </NavDropdown>
                                            </div>
                                            <div className='space-5'></div>
                                        </>
                                        :
                                        <>
                                            <div className='product-card-variant-dropdown'>
                                                <div
                                                    className='product-card-selected-variant' onClick={() => openmobilevariants()}>
                                                    {selected_variant.filter(vari => vari.productid == ele.productid).length > 0 && selected_variant.filter(vari => vari.productid == ele.productid)[0].variantname || ele.variantList[0].variantname}
                                                    {caretIcon}
                                                </div>
                                            </div>
                                            <div className='space-10'></div>
                                        </>



                                    :
                                    <>
                                        <div className='card-variant-title' >{ele.variantList.length > 0 && ele.variantList[0].variantname}</div>
                                        <div className='space-5'></div>
                                    </>

                            }
                            <div className='product-price'>
                                <div className="product-price product-price-revamp">
                                    <h5 className="Paragraph-16-M--Semibold">{localStorage.getItem("currency") + selling_price}</h5>
                                    {
                                        ele.mrp != ele.selling_price &&
                                        <h5 className="offer offer-revamp Paragraph-16-M--Regular">{localStorage.getItem("currency") + mrp}</h5>
                                    }
                                </div>
                                {
                                    ele.mrp != ele.selling_price &&
                                    <h5 className="offer-label-revamp Paragraph-12-XS--Semibold">{percentage}% OFF</h5>
                                }
                            </div>

                            <div className='space-10'></div>


                            <div className='buttons-product-detaildiv product-card-button-div'>
                                <div className='product-detail-buttongroup product-card-cart_btn '>
                                    {

                                        cartDetail.result.filter(ele1 => ele1.cart_status == 1 && ele1.product_id == ele.productid).length > 0
                                            &&
                                            cartDetail.result.filter(ele18 => (ele18.variantname == (selected_variant.filter(ele18 => ele18.productid == ele.productid).length > 0 ? selected_variant.filter(ele18 => ele18.productid == ele.productid)[0].variantname : ele.variantList.length && ele.variantList[0].variantname) && ele18.product_id == ele.productid)).length > 0
                                            ?


                                            <div className='addon-quantity cart-increment-container'>
                                                <div className={`addon-increment
                                         
                                         `}
                                                    onClick={function (event) {

                                                        updateQuantity(
                                                            parseInt(selected_variant.filter(ele16 => ele16.productid == ele.productid).length > 0
                                                                ?
                                                                cartDetail.result.filter(ele18 => ele18.variantname == selected_variant.filter(ele16 => ele16.productid == ele.productid)[0].variantname && ele18.product_id == ele.productid)[0].quantity
                                                                : cartDetail.result.filter(ele18 => (ele18.variantname == ele.variantList[0].variantname && ele18.product_id == ele.variantList[0].productid))[0].quantity) - 1,
                                                            selected_variant.filter(ele16 => ele16.productid == ele.productid).length > 0
                                                                ?
                                                                cartDetail.result.filter(ele18 => ele18.variantname == selected_variant.filter(ele16 => ele16.productid == ele.productid)[0].variantname && ele18.product_id == ele.productid)[0].id
                                                                : cartDetail.result.filter(ele18 => (ele18.variantname == ele.variantList[0].variantname && ele18.product_id == ele.variantList[0].productid))[0].id)

                                                    }}

                                                >


                                                    <BiMinus style={{ fontSize: "16px" }} />



                                                </div>

                                                <div className='addon-qunatity'>
                                                    {
                                                        productLoaderstatus ?
                                                            <ClipLoader
                                                                className="cart-btn-loader"
                                                                loading={true}
                                                                size={10}
                                                            />
                                                            :
                                                            selected_variant.filter(ele16 => ele16.productid == ele.productid).length > 0
                                                                ?
                                                                cartDetail.result.filter(ele18 => (ele18.variantname == (selected_variant.filter(ele16 => ele16.productid == ele.productid)[0].variantname) && ele18.product_id == ele.productid)).length > 0 ? cartDetail.result.filter(ele18 => (ele18.variantname == selected_variant.filter(ele16 => ele16.productid == ele.productid)[0].variantname && ele18.product_id == ele.productid))[0].quantity : 70
                                                                : cartDetail.result.filter(ele18 => ele18.variantname == (ele.variantList.length > 0 && ele.variantList[0].variantname)).length > 0 &&
                                                                cartDetail.result.filter(ele18 => (ele18.variantname == (ele.variantList.length > 0 && ele.variantList[0].variantname) && ele18.product_id == ele.productid))[0].quantity
                                                    }
                                                </div>

                                                <div className='addon-decrement'
                                                    onClick={function (event) {

                                                        updateQuantity(
                                                            parseInt(selected_variant.filter(ele16 => ele16.productid == ele.productid).length > 0
                                                                ?
                                                                cartDetail.result.filter(ele18 => ele18.variantname == selected_variant.filter(ele16 => ele16.productid == ele.productid)[0].variantname && ele18.product_id == ele.productid)[0].quantity
                                                                : cartDetail.result.filter(ele18 => (ele18.variantname == ele.variantList[0].variantname && ele18.product_id == ele.variantList[0].productid))[0].quantity) + 1,
                                                            selected_variant.filter(ele16 => ele16.productid == ele.productid).length > 0
                                                                ?
                                                                cartDetail.result.filter(ele18 => ele18.variantname == selected_variant.filter(ele16 => ele16.productid == ele.productid)[0].variantname && ele18.product_id == ele.productid)[0].id
                                                                : cartDetail.result.filter(ele18 => (ele18.variantname == ele.variantList[0].variantname && ele18.product_id == ele.variantList[0].productid))[0].id)
                                                    }}
                                                ><BiPlus style={{ fontSize: "16px" }} /></div>
                                            </div>


                                            : productLoaderstatus ?
                                                <div className={`product-detail-button cart-btn product-card-btn-2loader`} onClick={() => add2cartfun(ele)}>
                                                    <ClipLoader
                                                        className="cart-btn-loader"
                                                        loading={true}
                                                        size={14}
                                                    />
                                                </div>
                                                :
                                                <>
                                                    {
                                                        ele.stock_status == 1 ?
                                                            <div className={`product-detail-button cart-btn product-card-btn-2`} onClick={() => add2cartfun(ele)}>
                                                                <RiShoppingCart2Fill className="product-card-btn-icon" style={{ marginRight: "3px" }} />
                                                                ADD TO CART
                                                            </div> :
                                                            <div className={`product-detail-button cart-btn product-card-btn-outofstock`} style={{cursor:"not-allowed"}}>
                                                                Out Of Stock
                                                            </div>
                                                    }
                                                </>


                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (isDesktoporMobile && product_id_variant > 0 && product_id_variant == ele.productid) &&
                        <Sheet isOpen={mobilevariants} onClose={() => {
                            dispatch({ type: 'setmobilevariants', payload: false })
                        }
                        }
                        >
                            <Sheet.Container className='custom-mobilevariant-modalsheet'>
                                <Sheet.Content>
                                    <div className='custom-filter-sheet-haeder'>
                                        <div className='filter_title'>Select Variant</div>
                                        <div className='icon' onClick={() =>

                                            dispatch({ type: 'setmobilevariants', payload: false })

                                        }><IoClose size="20" /></div>
                                    </div>



                                    <div className='product-content'>
                                        <div className='product-card-variant-dropdownlist'>
                                            {
                                                ele.variantList.map((ele1, index) => {
                                                    return (
                                                        <div className={`product-card-variant-listitem 
                                                ${selected_variant.filter(vari => vari.productid == ele.productid).length > 0 ? (selected_variant.filter(vari => vari.productid == ele1.productid)[0].variantid == ele1.variantid ? "selected_variant_active" : " ") : (index == 0) && "selected_variant_active"}`}
                                                            key={index}
                                                            onClick={() => handlemobileClick(ele1.variantid, ele1.variantname)}

                                                        >
                                                            <div className='variant_title'>{ele1.variantname}</div>
                                                            <div className='product-price'>
                                                                <div className="product-price product-price-revamp">
                                                                    <h5 className="Paragraph-16-M--Semibold">{localStorage.getItem("currency") + ele1.selling_price}</h5>
                                                                    {
                                                                        ele1.mrp != ele1.selling_price &&
                                                                        <h5 className="offer offer-revamp Paragraph-16-M--Regular">{localStorage.getItem("currency") + ele1.mrp}</h5>
                                                                    }
                                                                </div>
                                                                {
                                                                    ele.mrp != ele.selling_price &&
                                                                    <h5 className="offer-label-revamp Paragraph-12-XS--Semibold">{ele.discount_percentage}% OFF</h5>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Sheet.Content>
                            </Sheet.Container>
                            <Sheet.Backdrop />
                        </Sheet>
                    }
                </>

            }

        </>
    );
});

export default ProductCard;

