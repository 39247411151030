import React from 'react';
import {PiSmileySadFill,PiSmileyFill} from 'react-icons/pi'
import {BiSolidWinkSmile} from 'react-icons/bi'
import {FaSmileWink} from 'react-icons/fa'
const FeedbackForm = () => {
    return (
        <div className='EmojiHead'>
            <div className='EmojiHead-subhead'>Rate your Experience</div>
            <div className='EmojiHead-Smilys'>
                <div className='EmojiHead-Smilys-sub'>
                <div className='EmojiHead-Smilys-sub-smily'><PiSmileySadFill/></div>
                <div className='EmojiHead-Smilys-sub-content'>Could've been </div>
                <div className='EmojiHead-Smilys-sub-content'>better </div>
                </div>
                <div className='EmojiHead-Smilys-sub'>
                <div className='EmojiHead-Smilys-sub-smily'><PiSmileyFill/></div>
                <div className='EmojiHead-Smilys-sub-content'>Average</div>
                </div>
                <div className='EmojiHead-Smilys-sub'>
                <div className='EmojiHead-Smilys-sub-smily'><BiSolidWinkSmile/></div>
                <div className='EmojiHead-Smilys-sub-content'>I enjoyed it!</div>
                </div>
              
            </div>
        </div>
    );
};

export default FeedbackForm;