import React, { createContext, useState } from "react";

export const StateContext = createContext();

const ManageStates = ({ children }) => {

    const [cartDetail, setcartDetail] = useState({
        result: []
    });
    const [cartDetaildup, setcartDetaildup] = useState(undefined);
    const [profileData, setprofileData] = useState([]);
    const [orderList, setorderList] = useState([]);
    const [mobilevariants, setmobilevariants] = useState(false);
    const [orderDetail, setorderDetail] = useState({});
    const [razzer, setRazzer] = useState([]);
    const [address, setAddres] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

    const [onchangeadress,setonchangeadress] = useState('');
    const [geoaddress,setcustomegeoaddress] = useState([]);

    const [selected_variant, setselected_variant] = useState([]);
    const [product_id_variant, setproduct_id_variant] = useState(0);
    const [quantity, setquantity] = useState(1);
    const [openLightGallery, setopenLightGallery] = useState(0);
    const [subscription, setSubscription] = useState([]);
    const [variant_error, setvariant_error] = useState('');
    const [current_variant, setcurrent_variant] = useState('');
    const [vendor_token, setvendor_token] = useState('');
    const [pwd_Email, setpwd_Email] = useState('');
    const [sel, setsel] = useState([]);
    const [sel1, setsel1] = useState([]);
    const [sel2, setsel2] = useState([]);
    const [addon, setaddon] = useState([]);
    const [prodDetailBottomSection, setprodDetailBottomSection] = useState([]);
    const [masterData, setmasterData] = useState({});
    const [loaderstatus, setLoaderstatus] = useState(false);
    const [bottomsheet, setbottomsheet] = useState(false);
    const [openvariant,setopenvariant] = useState(false);
    const [filtersbottomsheet, setfiltersbottomsheet] = useState(false);
    const [authToken, setauthToken] = useState('');
    const [authStatus, setauthStatus] = useState(false);
    const [otpToken, setotpToken] = useState('');
    const [step,setStep]=useState(1);
    const [showsearchdata, setshowdata] = useState(false);
    const [searchValue, setValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [filterPage, setFilterPage] = useState(1);



    const [filterArray, setfilterArray] = useState([]);
    const [bundelData, setbundelData] = useState([]);
    const [checkItems, setCheckItems] = useState([]);



    const [productcatData, setproductcatData] = useState([]);
    const [productcatFiltersData, setproductcatFiltersData] = useState([]);
    const [categoryData, setcategoryData] = useState([]);

    const [openModal, setopenModal] = useState(false);
    const [openaddressModal, setopenaddressModal] = useState(false);
    const [otpformType, setotpformType] = useState(false);
    const [otpform, setotpform] = useState(0);
    const [userData, setuserData] = useState({});
    const [deliverdatetime, setdeliverdatetime] = useState({});
    const [input_data, setinput_data] = useState(undefined);
    const [TabExpanded, setTabExpanded] = useState(1);
    const [HomePageData, setHomePageData] = useState([]);
    const [productDetailData, setproductDetailData] = useState([]);
    const [searchDetail, setsearchDetail] = useState([]);
    const [openform, setopenform] = useState(false);
    const [formValues, setFormValues] = useState({
        name: "",
        phone: "",
        address: "",
        pincode: "",
        state: "",
        addressId: "0",
        default: 1,
        addresstype: "Delivery"
    });
    const [billingformValues, setbillingFormValues] = useState({
        billingname: "",
        billingphone: "",
        billingaddress: "",
        billingpincode: "",
        billingstate: "",
        addressId: "0",
        default: 1,
        addresstype: "Delivery"
    });
    const [errors, setErrors] = useState({});
    const [billingerrors, setbillingErrors] = useState({});

    const [addressList, setaddressList] = useState(undefined);
    const [addressData, setaddressData] = useState([]);
    const [deliverslot, setdeliverslot] = useState(false);

    const [hasMore, SethasMore] = React.useState(true);
    const [addressOpen, setAddressopen] = useState(0);
    const [updatedProfilephone, setupdatedProfilephone] = useState({});
    const [filterChecked, setfilterChecked] = useState(0);
    const [categoryOpen, setcategoryOpen] = useState("level1");
    const [presntcategoryOpen, setpresentcategoryOpen] = useState("");
    const [presntsubcategoryOpen, setpresentsubcategoryOpen] = useState("");
    const [sidebar, setSidebar] = useState(false);
    const [checkedbilling, setcheckedbilling] = useState(false);
    const [coupanmsg, setCoupanmsg] = useState([]);
    const [billingAddress, setbillingAddress] = useState({});
    const [discount, setdiscount] = useState(0);
    
    return (
        <StateContext.Provider value={{
            cartDetail: cartDetail,
            setcartDetail: setcartDetail,
            setupdatedProfilephone: setupdatedProfilephone,
            updatedProfilephone: updatedProfilephone,
            loaderstatus: loaderstatus,
            setFormValues: setFormValues,
            setCoupanmsg: setCoupanmsg,
            coupanmsg: coupanmsg,
            setaddon: setaddon,
            addon: addon,
            formValues: formValues,
            setdiscount: setdiscount,
            discount: discount,
            bottomsheet: bottomsheet,
            setotpToken: setotpToken,
            setbillingFormValues: setbillingFormValues,
            billingformValues: billingformValues,
            setbillingErrors: setbillingErrors,
            billingerrors: billingerrors,
            SethasMore: SethasMore,
            hasMore: hasMore,
            setErrors: setErrors,
            errors: errors,
            razzer:razzer,
            orderList: orderList,
            setorderList: setorderList,
            setHomePageData: setHomePageData,
            HomePageData: HomePageData,
            otpToken: otpToken,
            setinput_data: setinput_data,
            input_data: input_data,
            setotpformType: setotpformType,
            otpformType: otpformType,
            setprofileData: setprofileData,
            setopenModal: setopenModal,
            openModal: openModal,
            setRazzer:setRazzer,
            setotpform: setotpform,
            otpform: otpform,
            setvariant_error:setvariant_error,
            variant_error:variant_error,
            profileData: profileData,
            setcheckedbilling: setcheckedbilling,
            checkedbilling: checkedbilling,
            setbottomsheet: setbottomsheet,
            setfiltersbottomsheet: setfiltersbottomsheet,
            subscription: subscription,
            setSubscription: setSubscription,
            filtersbottomsheet: filtersbottomsheet,
            setsel:setsel,
            setsel1:setsel1,
            setsel2:setsel2,
            sel2:sel2,
            setvendor_token:setvendor_token,
            vendor_token:vendor_token,
            sel:sel,
            sel1:sel1,
            setLoaderstatus: setLoaderstatus,
            setquantity:setquantity,
            quantity:quantity,
            setproductDetailData: setproductDetailData,
            setcurrent_variant:setcurrent_variant,
            current_variant:current_variant,
            setmobilevariants:setmobilevariants,
            mobilevariants:mobilevariants,
            productDetailData: productDetailData,
            setopenvariant:setopenvariant,
            openvariant,openvariant,
            authToken: authToken,
            setfilterChecked: setfilterChecked,
            filterChecked: filterChecked,
            setauthToken: setauthToken,
            authStatus: authStatus,
            setbillingAddress: setbillingAddress,
            setopenLightGallery:setopenLightGallery,
            openLightGallery:openLightGallery,
            setproductcatData: setproductcatData,
            selectedColor:selectedColor,
            setSelectedColor:setSelectedColor,
            productcatData: productcatData,
            billingAddress: billingAddress,
            setuserData: setuserData,
            userData: userData,
            setproductcatFiltersData: setproductcatFiltersData,
            productcatFiltersData: productcatFiltersData,
            setpresentsubcategoryOpen: setpresentsubcategoryOpen,
            setprodDetailBottomSection: setprodDetailBottomSection,
            prodDetailBottomSection: prodDetailBottomSection,
            presntsubcategoryOpen: presntsubcategoryOpen,
            TabExpanded: TabExpanded,
            setproduct_id_variant:setproduct_id_variant,
            product_id_variant:product_id_variant,
            selectedItem: selectedItem,
            setcartDetaildup:setcartDetaildup,
            cartDetaildup:cartDetaildup,
            openaddressModal:openaddressModal,
            setopenaddressModal:setopenaddressModal,
            address:address,
            deliverdatetime: deliverdatetime,
            setdeliverdatetime: setdeliverdatetime,
            setshowdata: setshowdata,
            setopenform: setopenform,
            setselected_variant:setselected_variant,
            setonchangeadress:setonchangeadress,
            onchangeadress:onchangeadress,
            selected_variant:selected_variant,
            setcustomegeoaddress:setcustomegeoaddress,
            geoaddress:geoaddress,
            showsearchdata: showsearchdata,
            setSidebar: setSidebar,
            setsearchDetail: setsearchDetail,
            setdeliverslot: setdeliverslot,
            deliverslot: deliverslot,
            setStep:setStep,
            step:step,
            setpwd_Email:setpwd_Email,
            setorderDetail:setorderDetail,
            orderDetail:orderDetail,
            pwd_Email:pwd_Email,
            searchDetail: searchDetail,
            setbundelData:setbundelData,
            bundelData:bundelData,
            sidebar: sidebar,
            setCheckItems: setCheckItems,
            checkItems: checkItems,
            filterPage: filterPage,
            setFilterPage: setFilterPage,
            openform: openform,
            setaddressData: setaddressData,
            setmasterData: setmasterData,
            masterData: masterData,
            setSelectedItem: setSelectedItem,
            setValue: setValue,
            searchValue: searchValue,
            addressData: addressData,
            setaddressList: setaddressList,
            setAddres:setAddres,
            addressList: addressList,
            setfilterArray: setfilterArray,
            filterArray: filterArray,
            setcategoryData: setcategoryData,
            categoryData: categoryData,
            setAddressopen: setAddressopen,
            addressOpen: addressOpen,
            setTabExpanded: setTabExpanded,
            setauthStatus: setauthStatus,
            setcategoryOpen, setcategoryOpen,
            setpresentcategoryOpen: setpresentcategoryOpen,
            presntcategoryOpen: presntcategoryOpen,
            categoryOpen: categoryOpen,
        }}>
            {children}
        </StateContext.Provider>
    );
};

export default ManageStates;