import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { WishListCard } from '../components/common';
import CartAction from '../redux/actions/CartAction';
import { useSelector,useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
const Wishlist = () => {
    const { deleteCart } = CartAction();

    const dispatch = useDispatch()

    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
  
    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteCart(input_data))
    }
    return (
        <div>
            <Container fluid>
            <Helmet>
        <title>{localStorage.getItem("title")} - WishList</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
      </Helmet>
      {
        cartDetail.result.length>0 ?
        <div>
        <div className='wishlist-maintitle mt-2'>My Wishlist <span className='mywishlist-item-count'> ({cartDetail.result.filter(ele => ele.cart_status == 2).length}  items)</span></div>
        <Row className='mt-2' >
            <div className='infinite-scroll-component__outerdiv'>
                <div className='infinite-scroll-component wishlist-main-div '>
              
                    {
                        cartDetail.result.filter(ele => ele.cart_status == 2).map(ele => {
                             return (
                                <Col lg={2} md={4} sm={4} xs={6} className="product-list wishlist-col">
                                    <WishListCard ele={ele} />
                                </Col>
                            )
                        })
                    }
                </div>
            </div>
        </Row>
        </div> :   <div className='custom-height'></div>
      }
     



































            </Container>
        </div>
    );
};

export default Wishlist;