import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { StateContext } from '../context/ManageStates';
import { ProductCard, NodataFound } from '../common';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink, useSearchParams } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const CatProducts = React.memo(({ type }) => {
    const dispatch = useDispatch();
    const isDesktoporLaptop = useMediaQuery({ query: `(max-width: 991px)` });
    const productcatData = useSelector(state => state.catlogdetail.productcatData);
    const productcatFiltersData = useSelector(state => state.catlogdetail.productcatFiltersData);
    const checkItems = useSelector(state => state.catlogdetail.checkItems);
    const hasMore = useSelector(state => state.catlogdetail.hasMore);
    const filterPage = useSelector(state => state.catlogdetail.filterPage);

    const [searchParams, setSearchParams] = useSearchParams();
    const [activesortby, setactivesortby] = useState('New Arrivals');




    const handleremove = (title, value) => {
        const ParamsValue = searchParams.get(title);
        var paramsArray = ParamsValue.split(",");
        var filteredArray = paramsArray.filter((ele) => ele !== value);
        searchParams.set(title, filteredArray.toString());
        if (filteredArray.length <= 0) {
            searchParams.delete(title);
        }
        setSearchParams(searchParams);
    }

    const handleclick = (val) => {
        if (val != 'new_arrivals') {
            searchParams.set(
                'order_by',
                val
            );
            if (val == 'asc') {
                setactivesortby('Low to High')
            } else {
                setactivesortby('High to Low')
            }
        }
        if (val == 'new_arrivals') {
            setactivesortby('New Arrivals')
            searchParams.delete('order_by');
        }
        setSearchParams(searchParams);
    }


    useEffect(() => {
        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        var finalInput1 = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value,
        })).filter(iop => iop.title == 'order_by')

        if (finalInput1.length > 0) {
            if (finalInput1[0].value == 'asc') {
                setactivesortby('Low to High')
            } else if (finalInput1[0].value == 'desc') {
                setactivesortby('High to High')
            } else {
                setactivesortby('New Arrivals')
            }
        }

    }, [searchParams])

    // dispatch({
    //     type: 'subfilteritems',
    //     payload: {
    //         filters: productcatFiltersData.display_items.products.length
    //     }
    // });


    return (
        <>

            <Col xxl={10} lg={9} sm={12} xs={12}>

                <Row className='mb-2'>
                    <Col lg={9} className="filter-summary-filterList ">

                        {
                            productcatData.filters.checkbox.map((element) => {
                                return { ...element, list: element.list.filter((subElement) => checkItems.includes(subElement.attr_value_id)) }
                            }).filter(ele => ele.list.length > 0).map(ele1 => {
                                return ele1.list.map(ele2 => {
                                    return (
                                        <div className='filter-summary-filter'>
                                            <div className='filter-summary-parent'>
                                                <h5 className='filter-details-data'>{ele2.attr_value_name}</h5>
                                                <div className='filter-summary-removeIcon' onClick={() => handleremove(ele1.title, ele2.attr_value_id)}><IoClose size="15" /></div>
                                            </div>
                                        </div>
                                    )
                                })
                            })
                        }
                    </Col>


                    {
                        !isDesktoporLaptop &&
                        <Col lg={3} >
                            <div class="hList">
                                <div>
                                    <div class="menu">
                                        <div class="menu-title menu-title_3rd">Sort By : <span className='active-title'>{activesortby}</span></div>
                                        <div class="menu-dropdown">
                                            <div onClick={() => handleclick('new_arrivals')} className={`${activesortby == 'New Arrivals' ? 'active-sortby-bg' : ''}`}>New Arrivals</div>
                                            <div onClick={() => handleclick('asc')} className={`${activesortby == 'Low to High' ? 'active-sortby-bg' : ''}`}>Price : Low to High</div>
                                            <div onClick={() => handleclick('desc')} className={`${activesortby == 'High to Low' ? 'active-sortby-bg' : ''}`}>Price : High to Low</div>
                                            {/* <div onClick={() => handleclick('Discount')} className={`${activesortby == 'Discount' ? 'active-sortby-bg' : ''}`}>Discount</div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    }


                </Row>
                {
                    productcatFiltersData.hasOwnProperty("display_items") ?
                        <InfiniteScroll

                            dataLength={productcatFiltersData.display_items.products.length}
                            next={() => {

                                dispatch({ type: 'setFilterPage', payload: filterPage + 1 })
                            }}
                            hasMore={hasMore}
                            scrollThreshold={"80%"}
                            loader={
                                productcatFiltersData.display_items.products.length >= 20 &&
                                <div style={{ textAlign: "center" }}>
                                    <Spinner animation="border" role="status" className='custom-spinner productlist-loader' />
                                </div>
                            }
                            // endMessage={<></>}
                            // refreshFunction={refresh}
                            style={{ overflow: "hidden" }}
                        >
                            <Row className='mb-4'>
                                {
                                    productcatFiltersData.display_items.products.map((ele, index) => {
                                        if (ele.variantList.length > 0) {
                                            var detail_url = `${ele.slug}?variant=${ele.variantList[0].variantid}`;
                                        } else {
                                            var detail_url = ele.slug;
                                        }
                                        return (
                                            <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={6} key={index} className="product-list">
                                                {
                                                    type == 'bundel' ?
                                                        <ProductCard ele={ele} />
                                                        :
                                                        // <NavLink className="text-decoration-none" to={`/product-details/${detail_url}`}>
                                                        <ProductCard ele={ele} key={ele.productid} />
                                                    // </NavLink>

                                                }

                                            </Col>
                                        )
                                    })
                                }
                            </Row>

                        </InfiniteScroll>
                        :
                        productcatFiltersData.hasOwnProperty("result") ? <NodataFound /> : <div className='custom-height'></div>
                }

            </Col>

        </>
    );
});

export default CatProducts;