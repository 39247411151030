import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import Lightbox from "yet-another-react-lightbox";
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import { useSearchParams } from 'react-router-dom';
import logo from '../../images/bg_image.webp';
import "yet-another-react-lightbox/plugins/counter.css";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';


export default function ProductDetailGallery({ product_images }) {
    const { slug } = useSearchParams()
    const [open, setOpen] = React.useState(false);

    const [my_swiper, set_my_swiper] = useState({});

    const [index, setindex] = useState(0);

    const [img, setimg] = useState([]);

    const slidechange = (e) => {
        setindex(e.realIndex)
    }
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    useEffect(() => {
        setimg([])
    }, [slug])

    useEffect(() => {
        setimg([])
        if (product_images.length > 0) {
            // setimg(Array(product_images.length >= 2 ? 4 : 2).fill(product_images).flat())
            setimg(product_images)
        }
    }, [product_images])

    return (
        <>
            {
                img.length > 1 &&
                <Col lg={2} className="padding-right-unset">
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        style={{ height: '460px', position: "relative" }}
                        direction={'vertical'}
                        onInit={(ev) => {
                            set_my_swiper(ev)
                        }}
                        lazy={true}
                        preloadImages={false}
                        slidesPerView={4.2}
                        freeMode={true}
                        centeredSlides={index >= 2 ? true : false}
                        // onReachEnd={()=>{my_swiper.slidechange(0)}}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        onSlideChange={(e) => slidechange(e)}
                        className="myprodcutdetailSwiper"
                    >
                        {
                            product_images.map((ele, index1) => {
                                return (
                                    <SwiperSlide className='desktop-myproductdetailswiperslide' key={index1}>
                                        <div onClick={() => {
                                            if (index1 == img.length - 1) {
                                                my_swiper.slideTo(0)
                                            } else {
                                                my_swiper.slideToClickedSlide(index1)
                                            }

                                        }}

                                        >
                                         

                                            <img referrerPolicy="no-referrer"  src={ele} loading="lazy" key={ele}
                                             onError={e => {
                                                e.currentTarget.src = logo
                                              }} />
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </Col>
            }

            <Col lg={10} className={`iop mb-4`}>




                <Lightbox
                    plugins={[Thumbnails, Slideshow, Zoom, Counter, Fullscreen]}
                    open={open}
                    close={() => setOpen(false)}
                    slides={
                        [...product_images.map(ele => {
                            return {
                                src:  ele,
                                width: 3840,
                                height: 2560
                            }
                        })]

                    }
                />

                <>
                    <Swiper
                        style={{
                            position: "relative",
                            '--swiper-navigation-color': '#706f6c',
                            '--swiper-pagination-color': '#fff',
                        }}
                        spaceBetween={10}
                        // navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mybigSwiper"
                    >
                        {
                            img.map((ele, index) => {
                                return (
                                    <SwiperSlide onClick={() => setOpen(true)} key={index}>
                                        <img referrerPolicy="no-referrer"  src={ele} loading='lazy'
                                         onError={e => {
                                            e.currentTarget.src = logo
                                          }} />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>

                </>

            </Col>

        </>
    );
}