import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";

import { HomeProductCard } from '../common';

import { BiSolidChevronRight, BiSolidChevronLeft } from "react-icons/bi";
import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';


const HomeCarouselCardComponent = ({ position, carouselCompData, loopindex, type, link, showbuttons }) => {
    const [my_swiper, set_my_swiper] = useState({});

    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const [index, setindex] = useState(0);

    const carusolesmall = useSelector(state => state.masterdata.carusolesmall);
    const carusolemedium = useSelector(state => state.masterdata.carusolemedium);
    const carusolelarge = useSelector(state => state.masterdata.carusolelarge);
    const carusoleextralarge = useSelector(state => state.masterdata.carusoleextralarge);
    // const carusole6 = useSelector(state => state.masterdata.carusole6);
    const slidechange = (e) => {
        // Add your logic to determine whether to allow the slide change or not.
        // For example, you can use the 'shouldAllowSlideChange' variable.
        const shouldAllowSlideChange = true; // Change this based on your conditions

        if (!shouldAllowSlideChange) {
            // If you don't want to allow the slide change, go back to the previous slide.
            my_swiper.slideTo(e.previousIndex);
        } else {
            // If you want to allow the slide change, update the index state.
            setindex(e.realIndex);
        }
    };
    return (
        <Row>
            <Col lg={12}>
                <div className='carousel-main-div'>
                    <div className={`home-carousel-component-header ${link == 'product-detail' ? 'detail-carousel-bottom' : ''}`}>
                        {/* <div className='home-carousel-componentheader-title'>{carouselCompData.section_title}</div> */}
                        <div className='Quality-products'>
                            <h5 className='Quality-products-details-left1'>

                            </h5>
                            <h5 className='Quality-products-details-left2'>
                                {carouselCompData.section_title}
                            </h5>
                        </div>
                        {
                            type == "view-all" &&
                            <NavLink to={carouselCompData.url} className="text-decoration-none">
                                <h5 className='home-carousel-componentheader-viewall'>{carouselCompData.button_label}</h5>
                            </NavLink>
                        }
                    </div>
                    {
                        showbuttons &&

                        (!isMobile && carouselCompData.sectionResult.length > 5) &&
                        <div className={`previous_${loopindex}  customprevious`}><BiSolidChevronLeft size="30" /></div>

                    }

                    <Swiper
                        effect="fade"
                        onInit={(ev) => {
                            set_my_swiper(ev)
                        }}
                        breakpoints={{
                            200: {
                                slidesPerView: carusolesmall,
                            },
                            700: {
                                slidesPerView: carusolemedium,
                            },
                            750: {
                                slidesPerView: carusolelarge,
                            },
                            1000: {
                                slidesPerView: carusoleextralarge,
                            },
                            1200: {
                                slidesPerView: carusoleextralarge,
                            },
                            1400: {
                                slidesPerView: carusoleextralarge,
                            },
                            1600: {
                                slidesPerView: carusoleextralarge,
                            },
                            1800: {
                                slidesPerView: carusoleextralarge,
                            },
                        }}
                        onSlideChange={(e) => slidechange(e)}
                        navigation={{
                            nextEl: `.next_${loopindex}`,
                            prevEl: `.previous_${loopindex}`,
                        }}
                        freeMode={true}
                        modules={[Navigation, FreeMode, Pagination, Scrollbar, A11y]}
                        className={`mycategorySwiper_${loopindex} mycategorySwiper${position}`}
                    >

                        {
                            carouselCompData.sectionResult.map((ele, index1) => {

                                return (
                                    <SwiperSlide
                                        onClick={() => {
                                            my_swiper.slideToClickedSlide(index1)
                                        }}
                                        key={index1}>
                                        <HomeProductCard item={ele} type={type} link={link} />
                                        {/* <div className='allcategory-title'><span>{ele.category_name}</span></div> */}
                                    </SwiperSlide>

                                )
                            })
                        }

                    </Swiper>
                    {
                        showbuttons &&
                        (!isMobile && carouselCompData.sectionResult.length > 5) &&
                        <div className={`next_${loopindex} ${link == 'product-detail' ? "customnextdetail" : "customnext"}`}><BiSolidChevronRight size="30" /></div>
                    }

                </div>
            </Col>


        </Row>
    );
};

export default HomeCarouselCardComponent;