import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import { BiSolidChevronRight, BiSolidChevronLeft } from "react-icons/bi";
import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import { Progressbar } from '../common';
import { BsStarFill, BsPersonCircle } from 'react-icons/bs';
import { FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import logo from '../../images/bg_image.webp';
const ReviewRatingSection = ({ position, carouselCompData, loopindex, type, link }) => {
    const [my_swiper, set_my_swiper] = useState({});
    //  const reviewdata =useSelector(state=>state.)
    const reviewdata = useSelector(state => state.cartdetail.reviewdetails);
    const ratingdata = reviewdata.map(ele => ele.rating)
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const [index, setindex] = useState(0);

    const slidechange = (e) => {
        setindex(e.realIndex)
    }

    const product_images = [
        "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
        "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
        "/assets/uploads/1008/products/4598/de04c7350ff43369fdcb71fb4d787e82.webp",
        "/assets/uploads/1008/products/4599/621a6b467687aefd598bb771d4ea9907.webp",
        "/assets/uploads/1008/products/4600/eedf1b2ae404b183a48c681a4ae2d980.webp",
        "/assets/uploads/1008/products/4621/2141ac7a2840ad530d21f2381fa39d60.webp",
        "/assets/uploads/1008/products/4644/dcb92513e68c376309879aeb11253368.webp",
        "/assets/uploads/1008/products/4671/eb4c169f53baa0ed5e6b0591c7dc5e3e.webp",
        "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
        "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
        "/assets/uploads/1008/products/4598/de04c7350ff43369fdcb71fb4d787e82.webp",
        "/assets/uploads/1008/products/4599/621a6b467687aefd598bb771d4ea9907.webp",
        "/assets/uploads/1008/products/4600/eedf1b2ae404b183a48c681a4ae2d980.webp",
        "/assets/uploads/1008/products/4621/2141ac7a2840ad530d21f2381fa39d60.webp",
        "/assets/uploads/1008/products/4644/dcb92513e68c376309879aeb11253368.webp",
        "/assets/uploads/1008/products/4671/eb4c169f53baa0ed5e6b0591c7dc5e3e.webp"
    ]

    const customers = [
        {
            id: 1,
            customer_name: "sai",
            ratings: 3,
            description: "This is one of the best Sweatshirts at this price range. I have tried other brands too. But this one is pocket friendly and Best value for money in my opinion. One should buy this if anyone making plan to buy a sweatshirt in this winter season.",
            photos: [
                "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
                "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
            ]
        },
        {
            id: 2,
            customer_name: "sai murali",
            ratings: 3,
            description: "Nice fitting, Good material, let's see how far it goes after usage.",
            photos: [
                "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
                "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
            ]
        },
        {
            id: 3,
            customer_name: "rohit sharma",
            ratings: 2,
            description: "Nice fitting, Good material, let's see how far it goes after usage.",
            photos: [
                "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
                "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
            ]
        },
        {
            id: 4,
            customer_name: "saras kumaruy",
            ratings: 4,
            description: "This is one of the best Sweatshirts at this price range. I have tried other brands too. But this one is pocket friendly and Best value for money in my opinion. One should buy this if anyone making plan to buy a sweatshirt in this winter season.",
            photos: [
                "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
                "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
            ]
        },
        {
            id: 5,
            customer_name: "sridhar",
            ratings: 1,
            description: "जिस कपड़े के ऊपर आप इसे पहनेंगे वो कपड़ा खराब हो जाएगा जैसा ये मेरा शर्ट खराब हुआ है। इसके अंदर का कॉटन शर्ट में चिपक जाता है।",
            photos: [
                "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
                "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
            ]
        },
        {
            id: 6,
            customer_name: "virat kohli",
            ratings: 5,
            description: "This is one of the best Sweatshirts at this price range. I have tried other brands too. But this one is pocket friendly and Best value for money in my opinion. One should buy this if anyone making plan to buy a sweatshirt in this winter season.",
            photos: [
                "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
                "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
            ]
        },
        {
            id: 7,
            customer_name: "sridhar",
            ratings: 5,
            description: "This is one of the best Sweatshirts at this price range. I have tried other brands too. But this one is pocket friendly and Best value for money in my opinion. One should buy this if anyone making plan to buy a sweatshirt in this winter season.",
            photos: [
                "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
                "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
            ]
        },
        {
            id: 8,
            customer_name: "saras kumaruy",
            ratings: 5,
            description: "This is one of the best Sweatshirts at this price range. I have tried other brands too. But this one is pocket friendly and Best value for money in my opinion. One should buy this if anyone making plan to buy a sweatshirt in this winter season.",
            photos: [
                "/assets/uploads/1008/products/4013/7f94891da36ceff988e28c0947637b6a.webp",
                "/assets/uploads/1008/products/4592/65dc2d390767a5c2b1342c002caaa95f.webp",
            ]
        }
    ]

    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const id = setInterval(() => {
            setProgress(Math.random() * 100);
        }, 3000);
        return () => {
            clearInterval(id);
        }
    }, []);


        const totalRatings = reviewdata.filter((ele) => ele.rating).length;
        const totalreview = reviewdata.filter((ele) => ele.review).length;
        const starRatings1 = reviewdata.filter((ele) => ele.rating == 1).length;
        const starRatings2 = reviewdata.filter((ele) => ele.rating == 2).length;
        const starRatings3 = reviewdata.filter((ele) => ele.rating == 3).length;
        const starRatings4 = reviewdata.filter((ele) => ele.rating == 4).length;
        const starRatings5 = reviewdata.filter((ele) => ele.rating == 5).length;

        const percentage1 = (100 * starRatings1) / totalRatings
        const percentage2 = (100 * starRatings2) / totalRatings
        const percentage3 = (100 * starRatings3) / totalRatings
        const percentage4 = (100 * starRatings4) / totalRatings
        const percentage5 = (100 * starRatings5) / totalRatings

        var sum=0;
        {ratingdata.map((star) => {
            // const percentage = calculatePercentage(star);
         
            sum =sum+star;
       
        })}
          

        const starsavg =sum/totalRatings
      
        return (
            reviewdata !=''  ?
            <>
           
            <div className={`home-carousel-component-header  reviewtitle-heading ${link == 'product-detail' ? 'detail-carousel-bottom' : ''}`}>
                    {/* <div className='home-carousel-componentheader-title'>{carouselCompData.section_title}</div> */}
                    <div className='Quality-products'>
                        <h5 className='Quality-products-details-left1'>

                        </h5>
                        <h5 className='Quality-products-details-left2'>
                        Customer Ratings & Reviews
                        </h5>
                    </div>
                    
                </div>
            <Row className='review-and-rating'>
                  
                <Col lg={4}>
                    {/* <div className='ratings-header'></div> */}
                  
                    <div >
                        <div className='ratedProduct-rating'>
                            {[...Array(5)].map((star, i) => {
                                const ratingValue = i + 1;
                                return (
                                    <label key={i}>
                                        <input
                                            type="radio"
                                            name="rating"
                                            value={ratingValue}
                                            style={{ display: 'none' }}
                                        />
                                        <FaStar
                                            className={(ratingValue <= (starsavg)) ? 'selected_value' : "unselected_value"}
                                            size={20}
                                        />
                                    </label>
                                );
                            })}
                            <span className='rating_summary'>{Math.round(starsavg)} Out of 5</span>
                            <div className='ratings'>
                                <div className='rating-count' style={{ paddingLeft: "3px" }}>
                                    <span>{totalRatings} Ratings </span>
                                    <span>& </span>
                                    <span> {totalreview} Reviews</span>
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                        <Progressbar value={percentage5} star={5} />
                        <Progressbar value={percentage4} star={4} />
                        <Progressbar value={percentage3} star={3} />
                        <Progressbar value={percentage2} star={2} />
                        <Progressbar value={percentage1} star={1} />
                        </div>
                    </div>
                </Col>
                <Col lg={8}>
                 
                    <div className="home-carousel-bg-wihte" key={index}>
                        {
                            (!isMobile) &&
                            <div className={`previous_${loopindex} previous${position} customprevious review-prevbutton`}><BiSolidChevronLeft size="30" /></div>
                        }
                        <Swiper
                            effect="fade"
                            onInit={(ev) => {
                                set_my_swiper(ev)
                            }}
                            breakpoints={{
                                200: {
                                    slidesPerView: 2.3,
                                },
                                700: {
                                    slidesPerView: 2.3,
                                },
                                750: {
                                    slidesPerView: 3,
                                },
                                1000: {
                                    slidesPerView: 3,
                                },
                                1200: {
                                    slidesPerView: 4,
                                },
                                1400: {
                                    slidesPerView: 4,
                                },
                                1600: {
                                    slidesPerView: 5,
                                },
                                1800: {
                                    slidesPerView: 4,
                                },
                            }}
                            onSlideChange={(e) => slidechange(e)}
                            navigation={{
                                nextEl: `.next_${position}`,
                                prevEl: `.previous_${position}`,
                            }}
                            freeMode={true}
                            modules={[Navigation, FreeMode, Pagination, Scrollbar, A11y]}
                            className={`mycategorySwiper_${loopindex} mycategorySwiper${position} rating_slider`}
                        >
                            {
                                reviewdata &&
                                reviewdata.map(item => {
                                    item.image.map((ele2, index2) => {
                                        // return ele.childCategory.map((ele1, index1) => {
                                        // return ele1.childCategory.map((ele2, index2) => {
                                        return (
                                            <SwiperSlide
                                            >
                                                <img  referrerPolicy="no-referrer" src={ localStorage.getItem("cdn") + ele2}
                                                     onError={e => {
                                                        e.currentTarget.src = logo
                                                      }}
                                                />

                                                {/* <div className='allcategory-title'><span>{ele.category_name}</span></div> */}
                                            </SwiperSlide>
                                        )
                                    })
                                })

                            }

                        </Swiper>
                        {
                            (!isMobile) &&
                            <div className={`next_${loopindex} next${position}  customnextdetail`}><BiSolidChevronRight size="30" /></div>
                        }
                    </div>

                    {
                        reviewdata.map(ele => {
                            return (
                                <div className='ratings_container'>
                                    <div className='ratings_user_headr'>
                                        <div>
                                            <BsPersonCircle />
                                        </div>
                                        <div className='ratings_user_title'>{ele.customer_name}</div>
                                    </div>

                                    <div className='ratedProduct-rating'>
                                        {[...Array(5)].map((star, i) => {
                                            const ratingValue = i + 1;
                                            return (
                                                <label key={i}>
                                                    <input
                                                        type="radio"
                                                        name="rating"
                                                        value={ratingValue}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <FaStar
                                                        className={(ratingValue <= (ele.rating)) ? 'selected_value' : "unselected_value"}
                                                        size={15}
                                                    />
                                                </label>

                                            );


                                        })}
                                    </div>
                                    <div className='ratings_user_description'>
                                        {ele.review}
                                    </div>
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>
            </> : ""
        );
    };

    export default ReviewRatingSection;

