import React, { useContext, useState } from 'react';

import { BillingAddressForm } from '../common';

import { Form, Row, Button } from "react-bootstrap";

import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import OrderAction from '../../redux/actions/OrderAction';
import { useSelector,useDispatch } from 'react-redux';

const CHeckoutPaymentMethod = ({ type }) => {

  const dispatch = useDispatch()

  const { postplaceorder } = OrderAction()

  const addressList = useSelector(state => state.addressdata.addressList);
  const billingformValues = useSelector(state => state.addressdata.billingformValues);
  const cartDetail = useSelector(state => state.cartdetail.cartDetail);
  const bottomsheet = useSelector(state => state.cartdetail.bottomsheet);

  const [checked, setchecked] = useState(2);

  const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

  const handlePayment = async (params) => {
    var input_data = {
      payment_method: checked
    }
    dispatch(postplaceorder(input_data));
  };



  return (
    <div className='checkout-payment-method'>
      {
        isDesktoporMobile ?
          <Row>

            <div className='buttons-product-detaildiv'>
              <div className='product-detail-buttongroup'>
                <div className='cart-detail-payment' onClick={() => {
                  dispatch({ type: 'setbottomsheet', payload: !bottomsheet })
                }}>
                  {localStorage.getItem("currency") + cartDetail.total} {!bottomsheet ? <BiChevronDown size="20" color="#ff7e00" /> : <BiChevronUp size="20" color="#ff7e00" />}
                </div>
                <div className='product-detail-button text-decoration-none' onClick={() => handlePayment()}>
                  Complete Payment
                </div>

              </div>
            </div>

          </Row>
          :
          <div className='mt-2'>
            {
              (addressList != undefined && addressList.length > 0 && billingformValues.addresstype == 'Billing') && <BillingAddressForm type={type} />
            }
          </div>
      }
    </div>
  );
};

export default CHeckoutPaymentMethod;