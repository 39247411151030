import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import logo from '../../images/bg_image.webp';
import { NavLink } from "react-router-dom";
const Brand31 = ({carouselCompData,carouselCompDataV2,carouselCompDataV3}) => {
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    
    const [first, second, third] = (carouselCompData && carouselCompData.sectionResult || []).map(ele => ele).sort((a, b) => a.id - b.id);
    const [first1, second1, third1] = (carouselCompDataV2 && carouselCompDataV2.sectionResult || []).map(ele => ele).sort((a, b) => a.id - b.id);
    const [first2, second2, third2] = (carouselCompDataV3 && carouselCompDataV3.sectionResult || []).map(ele => ele).sort((a, b) => a.id - b.id);
    
    // const [first1, second1, third1] = brands1;
    return (
        <div>
            <Container fluid>
         
              {
                carouselCompData &&
                <Row className='brand32-main '>

                <Col lg={4} md={4} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={first.url}>
                    <div className='single-banner'>
                    {
                            !isMobile ? 
                            <img className='brand-images' src={first.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={first.mobile_image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={second.url}>
                    <div className='single-banner'>
                    {
                            !isMobile ? 
                            <img className='brand-images' src={second.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={second.mobile_image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={third.url}>
                    <div className='single-banner'>
                    {
                            !isMobile ? 
                            <img className='brand-images' src={third.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={third.mobile_image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>


            </Row>

              }
               {
              carouselCompDataV2  &&

                <Row className='brand32-main '>

                <Col lg={6} md={6} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={first1.url}>
                    <div className='single-banner'>
                    {
                           ! isMobile ? 
                            <img className='brand-images' src={first1.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={first1.mobile_image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={second1.url}>
                    <div className='single-banner'>
                    {
                            !isMobile ? 
                            <img className='brand-images' src={second1.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={second1.mobile_image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={third1.url}>
                    <div className='single-banner'>
                    {
                            !isMobile ? 
                            <img className='brand-images' src={third1.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={third1.mobile_image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>


            </Row>

               }
               {
                carouselCompDataV3 &&
                <Row className='brand32-main '>

                <Col lg={3} md={3} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={first2.url}>
                    <div className='single-banner'>
                    {
                            !isMobile ? 
                            <img className='brand-images' src={first2.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={first2.mobile_image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={second2.url}>
                    <div className='single-banner'>
                    {
                            !isMobile ? 
                            <img className='brand-images' src={second2.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={second2.mobile_image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className='brands-col-maindiv'>
                <NavLink to={third2.url}>
                    <div className='single-banner'>
                    {
                            !isMobile ? 
                            <img className='brand-images' src={third2.image}    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>:
                            <img className='brand-images' src={third2.mobile_image }    onError={e => {
                                e.currentTarget.src = logo
                              }}></img>
                        }
                    </div>
                    </NavLink>
                </Col>


            </Row>
               }
               
            </Container>
        </div>
    );
};

export default Brand31;