import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, useParams, useLocation } from 'react-router-dom';
// import { useSearch } from '../hooks';
import { useSelector, useDispatch } from "react-redux";
import SearchAction from "../redux/actions/SearchAction";
const useFilters = (callback) => {

  const { getFilterSearchResult } = SearchAction()

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch()
  const { slug } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasMore = useSelector(state => state.catlogdetail.hasMore);
  const filterPage = useSelector(state => state.catlogdetail.filterPage);
  const input_data = useSelector(state => state.catlogdetail.input_data);
  const checkItems = useSelector(state => state.catlogdetail.checkItems);

  const location = useLocation()

  var pathname = location.pathname.split("/")[1];

  useEffect(() => {
    dispatch({ type: 'setproductcatFiltersData', payload: [] })
    dispatch({ type: 'setCheckItems', payload: [] })
    dispatch({ type: 'setFilterPage', payload: 1 })
    setIsSubmitting(true);
    var queryObj = {};
    for (const entry of searchParams.entries()) {
      queryObj = Object.fromEntries([...searchParams]);
    }

    var finalInput = Object.entries(queryObj).map(([key, value]) => ({
      title: key,
      value: value.split(",").map((ele) => ele.split("_")[0]),
    })).filter(iop => iop.title != 'order_by')

    var finalInput1 = Object.entries(queryObj).map(([key, value]) => ({
      title: key,
      value: value,
    })).filter(iop => iop.title == 'order_by')

    if (finalInput.length > 0) {
      var finaloutput = finalInput.flatMap(o => o.value);
      dispatch({ type: 'setCheckItems', payload: finaloutput })
    } else {
      var finaloutput = [];
    }

    var data = {
      ...((pathname != 'category' && pathname != 'subcategory') && { search: slug }),
      "limit": 50,
      "filter_update": 1,
      "type": (finaloutput.length > 0 || finalInput1.length > 0) ? "filtered" : "non-filtered",
      "finalOutput": finaloutput,
      "filter_attributes": finalInput,
      ...(finalInput1.length > 0 && {
        "filter_order": {
          "fiter_type": "selling_price",
          "fiter_by": finalInput1[0].value
        }
      }),
      ...(pathname == 'category' && { slug: slug }),
      ...(pathname == 'subcategory' && { slug: slug }),
      "page": 1,
      "pathname": pathname
      // "filter_categories": pathname == 'products-category' ? [slug] : selectedItem != null ? [selectedItem.split("@")[1]] : []
    }
    dispatch({ type: 'SethasMore', payload: true })
    dispatch({ type: 'setinput_data', payload: data })
  }, [searchParams])

  useEffect(() => {
    if (filterPage != 1 && isSubmitting && (input_data.slug == slug || input_data.search == slug)) {

      var queryObj = {};
      for (const entry of searchParams.entries()) {
        queryObj = Object.fromEntries([...searchParams]);
      }

      var finalInput = Object.entries(queryObj).map(([key, value]) => ({
        title: key,
        value: value.split(",").map((ele) => ele.split("_")[0]),
      })).filter(iop => iop.title != 'order_by')

      var finalInput1 = Object.entries(queryObj).map(([key, value]) => ({
        title: key,
        value: value,
      })).filter(iop => iop.title == 'order_by')

      if (finalInput.length > 0) {
        var finaloutput = finalInput.flatMap(o => o.value);
      } else {
        var finaloutput = [];
      }
      var data = {
        ...((pathname != 'category' && pathname != 'subcategory') && { search: slug }),
        "limit": 50,
        "filter_update": 1,
        "type": (finaloutput.length > 0 || finalInput1.length > 0) ? "filtered" : "non-filtered",
        "finalOutput": finaloutput,
        "filter_attributes": finalInput,
        ...(finalInput1.length > 0 && {
          "filter_order": {
            "fiter_type": "selling_price",
            "fiter_by": finalInput1[0].value
          }
        }),
        "filter_attributes": finalInput,
        ...(pathname == 'category' && { slug: slug }),
        ...(pathname == 'subcategory' && { slug: slug }),
        "page": filterPage,
        "pathname": pathname
        // "filter_categories": pathname == 'products-category' ? [slug] : selectedItem != null ? [selectedItem.split("@")[1]] : []
      }
      dispatch({ type: 'setinput_data', payload: data })
    }
  }, [filterPage])



  useEffect(() => {
    if (input_data != undefined && Object.keys(input_data).length > 0 && input_data.pathname == pathname) {
      if (!input_data.hasOwnProperty("filter_order") && checkItems.length <= 0 && (input_data.slug == slug || input_data.search == slug)) {
        if (callback) {
          callback()
        }
      }
    }

    if (input_data != undefined && Object.keys(input_data).length > 0 && input_data.type == 'filtered' && input_data.pathname == pathname) {
      dispatch(getFilterSearchResult(input_data))
    }
  }, [input_data])

  return { input_data };
};


export default useFilters;
