import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import logo from '../../images/bg_image.webp';
import { useSelector } from "react-redux";
import appstore from '../../images/appstore.webp';
import iosstore from '../../images/ios-store.webp';
import { BsWhatsapp, BsLinkedin, BsInstagram, BsFacebook } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";

const Footer = () => {
  const footerdata = useSelector(state => state.masterdata.footerdata);
  const [show, setShow] = useState(7);
  const [buttonShow, setButtonShow] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const handelViewMore = () => {
    setShow(-1);
    setButtonShow(false);
  };

  const handelViewLess = () => {
    setShow(7);
    setButtonShow(true);
  };

  return (
    <>

      <div className="footerSecondContainer">
        <Container

          className="maxWidthContainerFluid paddingContainerFluid"
        >
          <Row >
            <Col lg={4} md={4} sm={12} xs={12}>
              <Row>
                <Col lg={12}>




                  <img
                    referrerPolicy="no-referrer"
                    src={localStorage.getItem('footer_logo')}
                    onError={e => {
                      e.currentTarget.src = logo
                    }}
                    className="footerLogoimage"
                  />
                  {/* <h3 className="footer_descip">{localStorage.getItem("address")}</h3> */}
                  <p className="footer_descip">
                    <div dangerouslySetInnerHTML={{ __html: localStorage.getItem("domain_descrption") }} />


                  </p>
                </Col>
              </Row>
              {/* <Row style={{ paddingTop: "8px" }}>
                <Col lg={12}>

                  <div >
                    <h1 className="Connectwithus">Connect with us</h1>
                    <div className="sosalmidiaimg">
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="facebook"
                      >
                        <BsFacebook color="#fff" size="20" />

                      </a>

                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="instagram"
                      >
                        <BsInstagram color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="whatsapp"
                      >
                        <BsWhatsapp color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="linkedIn"
                      >
                        <BsLinkedin color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="twitter"
                      >
                        <AiOutlineYoutube color="#fff" size="25" />
                      </a>
                    </div>
                  </div>
                </Col>
              </Row> */}
            </Col>
            <Col lg={5} md={5} sm={12} xs={12}>
              <Row>
                <Col lg={12}>


                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <h1 className="wearealways">We're always here to help you</h1>
                  <p className="Reachout">Reach out to us through any of these support channels</p>
                  <div className="midiacont">
                    <div dangerouslySetInnerHTML={{ __html: localStorage.getItem("domain_address") }} />
                    <div style={{ display: "flex" }}>

                      <p className="massages">
                        whatsapp : {" "}
                      </p>
                      <p className="massages" style={{ marginLeft: "7px" }}>
                        <NavLink to={`https://wa.me/${localStorage.getItem("domain_whatsapp")}`} className={"text-decoration-none"} target="_blank">
                        {localStorage.getItem("domain_whatsapp")}
                        </NavLink>
                      </p>
                    </div>

                    <div style={{ display: "flex" }}>
                      <p className="massages">
                        email : {" "}
                      </p>
                      <p className="massages" style={{ marginLeft: "7px" }}>
                        {
                           <NavLink to={`mailto:${localStorage.getItem("domain_email")}`} className={"text-decoration-none"} target="_blank">
                      
    {localStorage.getItem("domain_email")}
                          </NavLink>
                        }
                    
                      </p>
                    </div>

                  </div>
                </Col>
              </Row>
            </Col>
            {/* <Col lg={3} md={3}>
              <Row >
                <Col lg={12} sm={10} md={10} xs={10}>
                  <div className="app_downlod">
                    <p className="secondfooterHeading">
                      Download App
                    </p>
                  </div>
                  <Row>
                    <Col lg={6} xs={6} sm={6}>
                      <NavLink
                        to={`https://play.google.com/store/apps/details?id=www.ourshopee.com`}
                        target="_blank"
                      >
                        <img  
                        referrerPolicy="no-referrer"
                          className="apple_icon"
                          src={appstore}
                          alt="Android_Appstore"
                        />
                      </NavLink>
                    </Col>
                    <Col lg={6} xs={6} sm={6}>
                      <NavLink
                        to={`https://apps.apple.com/us/app/ourshopee-online-shopping/id1226954989`}
                        target="_blank"
                      >
                        {" "}
                        <img 
                        referrerPolicy="no-referrer"
                          className="apple_icon"
                          src={iosstore}
                          alt="online-shopping"
                        />
                      </NavLink>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col> */}
          </Row>
          {


            !isMobile ?
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="footer-types">
                  <div className="middle_paragraph">
                    {
                      footerdata.length > 0 &&
                      footerdata.map((ele, index) => {
                        return (
                          <NavLink to={`/policies/${ele.list_key}`} className="dddddd">
                            <h5 className="footer-line">
                              {index !== 0 && " | "}
                            </h5>
                            {" "}
                            {ele.title}{" "}
                          </NavLink>
                        )
                      })
                    }

                  </div>
                </Col>   </Row>
              :
              <Row className="fotter-contact">

                {
                  footerdata.length > 0 &&
                  footerdata.map((ele, index) => {
                    return (

                      <Col lg={6} md={4} sm={6} xs={6} className="footer-text-contant">
                        <NavLink to={`/policies/${ele.list_key}`} className={"text-decoration-none"} >
                          {ele.title}
                        </NavLink>
                      </Col>


                    )
                  })
                }

              </Row>
          }
          <Row style={{ paddingTop: "20px" }}>

            <Col lg={12}>
              <div className="footer_component">
                <p className="ourshop_com">{localStorage.getItem("website_copyright")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
