import React, { useState } from 'react';
import { BsChevronLeft, BsChevronDown } from "react-icons/bs";
import { useCollapse } from 'react-collapsed'
import { FaPlus } from "react-icons/fa6";
import { FiMinus } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
const FilterCategories = ({ item }) => {
  const[open,setOpen]=useState(true)
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(
    {
      defaultExpanded: true
    }
  )
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className='custom-border-bottom'>
      <div >
        <button type="button" className="menuShow catgeories">
          <div className='arrow' {...getToggleProps()}>{isExpanded ? <BsChevronDown /> : <BsChevronLeft />}</div>
          <NavLink to={`/category/${item.slug}`} className="text-decoration-none">
            <p className="filterTitle">{item.label}</p>
          </NavLink>
        </button>
      </div>
      <div className="mb-2 filterItem" {...getCollapseProps()}>

        <div className='filterItems'>

          <ul style={{ paddingLeft: "33px" }}>
            {
              item.children.sort((a, b) => a.label.localeCompare(b.label)).map((ele,index) => {

                return (
                  <>
                
                    <li>
                      <div className='list list-sub'>
                      <NavLink to={`/subcategory/${ele.slug}`} key={index} className="text-decoration-none">
                        <div >{ele.label}</div>
                        </NavLink>
                        <div className='sub-icon-expand'>  {!open ? <FaPlus onClick={handleToggle} /> : <FiMinus onClick={handleToggle} />}
                         </div>
                      </div>
                    </li>
            


                  {ele.children.map((item, childIndex) => (
          <NavLink to={`/subcategory/${item.slug}`} key={childIndex} className="text-decoration-none">
            <li>
              <div className='list'>
               {
                open &&
                <div className='sub-sub-items'>{item.label}</div>
               } 
              </div>
            </li>
          </NavLink>
        ))}
                   </>
                );


              
              })

            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterCategories;