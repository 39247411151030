import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import Sheet from 'react-modal-sheet';
import { Link } from 'react-router-dom';
import { CartList, PaymentMethodComp, CartPaymentMethod, EmptyCart } from '../components/common';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useSelector, useDispatch } from 'react-redux';
import CartAction from '../redux/actions/CartAction';
import { Helmet } from 'react-helmet';
const Cart = () => {
    const dispatch = useDispatch()
    const { getCartList, checkcoupan } = CartAction();
    // const { checkcoupan } = useOrders();
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const coupanmsg = useSelector(state => state.cartdetail.coupanmsg);
    const bottomsheet = useSelector(state => state.cartdetail.bottomsheet);
    const cartDetaildup = useSelector(state => state.cartdetail.cartDetaildup);
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    const [coupon_code_value, setcoupon_code_value] = useState("");

    useEffect(() => {
        dispatch(getCartList())
    }, [])


    const coupanUpdated = () => {

        const input_data = {
            coupan_code: coupon_code_value
        }
        dispatch(checkcoupan(input_data))
    }

    const handlechange = (e) => {
        setcoupon_code_value(e.target.value)
    }

    return (
        <Container className='my-cart-container'>
            <Helmet>
                <title>{localStorage.getItem("title")} - Cart</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            {
                cartDetaildup != undefined ? (
                    cartDetail.result.filter(ele => ele.cart_status == 1).length > 0 ?
                        <Row className='my-cart'>
                            <div className='my-cart-container'>
                                <div className='my-cart-heading'>My Cart <span>({cartDetail.result.filter(ele => ele.cart_status == 1).length} items)</span></div>
                            </div>
                            <Col lg={8} md={12}>
                                <CartList />

                            </Col>
                            <Col lg={4} md={12} >
                                <div className='coupon'>
                                    <div className='coupon_header'>Apply coupon</div>
                                    <div className="input-area" >
                                        <div className="input-box-div">
                                            <input type="text" class="coupon-code-input-vhr-not-apld" name="coupon_code" onChange={(e) => handlechange(e)} id="couponCodeInput" placeholder="Enter coupon code" />
                                            <button className="rilrtl-button button apply-button " onClick={coupanUpdated}>Apply</button>
                                        </div>
                                        <div className='coupanmsg'>{coupanmsg.message}</div>
                                    </div>
                                </div>

                                <div className='payment_method mt-3 mb-3'>
                                    <PaymentMethodComp screentype={"cart"} />
                                </div>

                                {
                                    !isDesktoporMobile ?

                                        authStatus ?
                                            <Link to="/checkout" className=' text-decoration-none'>
                                                <div className='my-cart-button' >
                                                    Continue
                                                </div>
                                            </Link>
                                            :
                                            <Link to="/login?redirect_url=cart" className=' text-decoration-none'>
                                                <div className='my-cart-button' >
                                                    Login / Register
                                                </div>
                                            </Link>
                                        :
                                        <Row>
                                            <div className='buttons-product-detaildiv'>
                                                <div className='product-detail-buttongroup'>
                                                    <div className='cart-detail-payment' onClick={() => dispatch({ type: 'setbottomsheet', payload: !bottomsheet })}>
                                                        {localStorage.getItem("currency") + cartDetail.total} {!bottomsheet ? <BiChevronDown size="20" color="#ff7e00" /> : <BiChevronUp size="20" color="#ff7e00" />}
                                                    </div>
                                                    {authStatus ?
                                                        <Link to="/checkout" className='product-detail-button text-decoration-none'>
                                                            Continue
                                                        </Link>
                                                        :
                                                        <Link to="/login?redirect_url=checkout" className='product-detail-button text-decoration-none'>
                                                            Login / Register
                                                        </Link>
                                                    }

                                                </div>
                                            </div>

                                            <Sheet isOpen={bottomsheet} onClose={() => dispatch({ type: 'setbottomsheet', payload: false })}
                                            >
                                                <Sheet.Container className='custom-cart-modalsheet'>
                                                    <Sheet.Content>
                                                        <CartPaymentMethod type={"dropdown"} />
                                                        <div className={`product-detail-buttongroup${!bottomsheet ? 'cart-bottomsheet-active' : ''}`}>
                                                            <div className='cart-detail-payment' onClick={() => dispatch({ type: 'setbottomsheet', payload: !bottomsheet })}>
                                                                {localStorage.getItem("currency") + cartDetail.total} {!bottomsheet ? <BiChevronDown size="20" color="#ff7e00" /> : <BiChevronUp size="20" color="#ff7e00" />}
                                                            </div>
                                                            {authStatus ?
                                                                <div className='product-detail-button'>
                                                                    Continue
                                                                </div>
                                                                :
                                                                <div className='product-detail-button'>
                                                                    Login / Register
                                                                </div>
                                                            }
                                                        </div>
                                                    </Sheet.Content>
                                                </Sheet.Container>
                                                <Sheet.Backdrop />
                                            </Sheet>
                                        </Row>
                                }
                            </Col>
                        </Row>
                        :
                        <EmptyCart />
                )
                    :
                    <div className='custom-height'></div>
            }

        </Container>
    );
};

export default Cart;