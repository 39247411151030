import React, { useEffect, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../images/bg_image.webp';
const HomeProductCard2 = ({ type, heading, item, link }) => {
    const [original_price, setoriginal_price] = useState(0);

    const dispatch = useDispatch()

    const addon = useSelector(state => state.productdetail.addon);

    const updateQuantity = (quantity, id) => {


        let updatedItem = addon.map(ele => {
            if (ele.product_id == id) {
                ele["quantity"] = quantity;
                return ele
            } else {
                return ele
            }
        })

        dispatch({ type: 'setaddon', payload: updatedItem })
    }

    const clicked = (type, id) => {
        let updatedItem = addon.filter(ele => ele.product_id != id)
        dispatch({ type: 'setaddon', payload: updatedItem })
    }

    return (
        <div className={"text-decoration-none"} style={{ width: "100%" }}>
            <div className={`carousel-componet-card ${type == 'homecomponent4' ? "half-carousel-card" : ""}`}>

                <div className={`home-product-card custom-border-grey ${type == 'homecomponent4' ? "" : ""}`}>
                    <div className={`home_product_image ${type == 'homecomponent4' ? " " : "home_produt_image_extracss"}`}>

                        <img referrerPolicy="no-referrer" src={item.product_images} className={type == 'homecomponent4' ? 'img-half-carousel' : ''}
                         onError={e => {
                            e.currentTarget.src = logo
                          }}
                        />
                    </div>
                    {/* <div className='home-product-card-title'>{item.title}</div> */}


                    <div class="product-price text-center d-block">
                        <div class="product-price-revamp">
                            <h5 class="Paragraph-16-M--Semibold">{localStorage.getItem("currency") + item.selling_price}</h5>
                        </div>
                    </div>
                    <div className={`add-ao-icon-revamp ${addon.filter(ele => ele.product_id == item.productid).length > 0 ? "active-bg-orange" : " "}`}
                        onClick={function (event) {
                            if (addon.filter(ele => ele.product_id == item.productid).length <= 0) {
                                dispatch({
                                    type: 'setaddon', payload: [
                                        ...addon,
                                        {
                                            ...item,
                                            heading: heading,
                                            product_id: item.productid,
                                            quantity: 1
                                        }

                                    ]
                                })


                                // addToCart(item.productid, 'product')
                            }
                        }}
                    >
                        {
                            addon.filter(ele => ele.product_id == item.productid).length > 0 ?
                                <div className='addon-quantity'>
                                    <div className={`addon-increment`}

                                        onClick={function (event) {
                                            if (addon.filter(ele => ele.product_id == item.productid)[0].quantity == 1) {
                                                clicked('cart', item.productid)
                                            } else {
                                                updateQuantity(parseInt(addon.filter(ele => ele.product_id == item.productid)[0].quantity) - 1, item.productid)
                                            }
                                        }}

                                    ><BiMinus style={{ fontSize: "20px" }} /></div>

                                    <div className='addon-qunatity'>{addon.filter(ele => ele.product_id == item.productid)[0].quantity}</div>
                                    <div className='addon-decrement' onClick={() => updateQuantity(parseInt(addon.filter(ele => ele.product_id == item.productid)[0].quantity) + 1, item.productid)}><BiPlus style={{ fontSize: "20px" }} /></div>
                                </div>
                                : "Add"
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeProductCard2;