import { useSelector } from "react-redux";
import Apis from "../../services/Apis";
import Cookies from 'universal-cookie';

const ProductAction = () => {
  const { getProductDetailService, SaveRecentlyViewedApi,getRelatedItemsApi } = Apis();

  var cookies = new Cookies();

  const getProductDetail = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await getProductDetailService(input_data);
    if (response.data.status == "success") {
      dispatch({
        type: 'setproductDetailData',
        payload:  response.data.productList
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    } else {
      dispatch({
        type: 'setproductDetailData',
        payload: []
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };
  const getRelatedItems = (input_data) => async dispatch => {
   
    const response = await getRelatedItemsApi(input_data);
    if (response.data.status == "success") {
      dispatch({
        type:'setprodDetailBottomSection',
        payload: response.data.data
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    } else {
      dispatch({
        type: 'setprodDetailBottomSection',
        payload: []
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };
  const SaveRecentlyViewed = (input_data) => async dispatch => {
    await SaveRecentlyViewedApi(input_data);
  };

  return {
    getProductDetail:getProductDetail,
     getRelatedItems:getRelatedItems,
     SaveRecentlyViewed:SaveRecentlyViewed
  }
};

export default ProductAction;
