import React, { useContext } from 'react'
const CheckoutTabs = ({ id, title, info }) => {

    return (
        <article className='question'>
            <div className={`custom-checkout-accordionheader accordionheader-active`}>
                <div className='question-title'>
                    {title}
                </div>
               
            </div>
            <div className='custom-checkout-accordionbody'>{info}</div>
        </article>
    )
}

export default CheckoutTabs