import React, { useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';
const SubscriptionStatus = () => {
    const masterData = useSelector(state => state.masterdata.masterData);
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const Comp1 = ({ heading,data }) => {
        return (
            <Row className='SubscriptionStatus-account '>
                <div className='SubscriptionStatus-account-row-head'>{heading}</div>
                <Col lg={6} className='SubscriptionStatus-account-row-col'>
                    <div className='SubscriptionStatus-account-list'>Account Status</div>
                    <div className='SubscriptionStatus-account-list-sub'>active</div>
                </Col>
                <Col lg={6} className='SubscriptionStatus-account-row-col'>
                    <div className='SubscriptionStatus-account-list'>Account type</div>
                    <div className='SubscriptionStatus-account-list-sub'>{data.account_type}</div>
                </Col>
                <Col lg={6} className='SubscriptionStatus-account-row-col'>
                    <div className='SubscriptionStatus-account-list'>Account Created</div>
                    <div className='SubscriptionStatus-account-list-sub'>{data.created_date}</div>
                </Col>
              
                
            
            </Row>
        )
    }
    const Comp2 = ({ heading,data }) => {
        return (
            <Row className='SubscriptionStatus-account '>
                <div className='SubscriptionStatus-account-row-head'>{heading}</div>
                <Col lg={6} className='SubscriptionStatus-account-row-col'>
                    <div className='SubscriptionStatus-account-list'>Subscription Name</div>
                    <div className='SubscriptionStatus-account-list-sub'>{data.name}</div>
                </Col>
                <Col lg={6} className='SubscriptionStatus-account-row-col'>
                    <div className='SubscriptionStatus-account-list'>Price / Duration</div>
                    <div className='SubscriptionStatus-account-list-sub'>{`${data.price} / ${data.type}`}</div>
                </Col>
                <Col lg={6} className='SubscriptionStatus-account-row-col'>
                    <div className='SubscriptionStatus-account-list'>Subscription Start</div>
                    <div className='SubscriptionStatus-account-list-sub'>{data.subcription_date}</div>
                </Col>
                <Col lg={6} className='SubscriptionStatus-account-row-col'>
                    <div className='SubscriptionStatus-account-list'>Valid Untill</div>
                    <div className='SubscriptionStatus-account-list-sub'>{data.subcription_end}</div>
                </Col>
            </Row>
        )
    }
    return (
        <Container className='SubscriptionStatus-main-head'>

            {
                authStatus && Object.keys("masterData").length > 0 && masterData.hasOwnProperty("account_overview") &&
                <Comp1 heading={"Account Overview"} data={masterData.account_overview}/>
            }
            {
                authStatus && Object.keys("masterData").length > 0 && masterData.hasOwnProperty("subscription") &&
                <Comp2 heading={"My Subscription"} data={masterData.subscription}/>
            }
        </Container>
    );
};

export default SubscriptionStatus;