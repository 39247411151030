import Carousel from 'react-bootstrap/Carousel';
import { useMediaQuery } from 'react-responsive';
import React, { useState, useEffect } from 'react';
import { PiShoppingCartFill } from "react-icons/pi";
import { NavLink } from 'react-router-dom';
import logo from '../../images/bg_image.webp';
const HomeCarousel = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
  const [imageLoaded, setImageLoaded] = useState(Array(data.sectionResult.length).fill(false));

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === data.sectionResult.length - 1 ? 0 : prevIndex + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, [data.sectionResult.length]);

  const handleImageLoad = (index) => {
    setImageLoaded((prevImageLoaded) => {
      const updatedImageLoaded = [...prevImageLoaded];
      updatedImageLoaded[index] = true;
      return updatedImageLoaded;
    });
  };

  return (
    <>
      <Carousel controls={true} indicators={true}>
        {data.sectionResult.map((Element, index) => (
          <Carousel.Item className="who_we_are_img" key={index}>
           <NavLink to={Element.url} className="text-decoration-none">
              {!imageLoaded[index] && (
                <div className="carousel-img-placeholder skeleton">
                  {/* Loading placeholder content */}
                </div>
              )}

              <img
                referrerPolicy="no-referrer"
                className="home_carousel_img"
                style={{ display: imageLoaded[index] ? 'block' : 'none' }}
                src={isMobile ? Element.mobile_image : Element.image}
                onLoad={() => handleImageLoad(index)}
                alt={`Carousel Image ${index}`}
                onError={e => {
                  e.currentTarget.src = logo
                }}
              />
               </NavLink>
             {/* <div className='home-carousel-banner-content'>
              <div className='homebanner-title'>
             {Element.title}
              </div>
              <div className='homebanner-discription'>
              {Element.description}
              </div>
              <NavLink to={Element.url} className="text-decoration-none">
                {
                  Element.button_label.length >0 &&
                  <div className='homebanner-button'>
                  <div  className='homebanner-cart-main-div rounded-circle'>
                  <PiShoppingCartFill  className='homebanner-cart-symbol' />
                  </div>
                
                {Element.button_label}
                </div>
                }
              </NavLink>
             </div> */}
          
            {
              !isMobile &&
              <div className="overlay-b-t-dark"></div>
            }
            
          </Carousel.Item>
        ))}
        {/* {
          data.sectionResult && data.sectionResult.length > 1 &&
          <NavLink to={Element.url}>
            <div className='custom-carousel-indicators'>
              <ul className="lines">
                {data.sectionResult.map((Element, index) => (
                  <li className={`${activeIndex === index ? 'active' : ''}`} key={index} onClick={() => setActiveIndex(index)}>
                    <a className="lines-item" href="#">
                      <div className="lines-dash"></div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </NavLink>
        } */}

      </Carousel>
    </>
  );
};

export default HomeCarousel;