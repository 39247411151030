import React, { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineSearch } from 'react-icons/ai';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import SearchAction from '../../redux/actions/SearchAction';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../images/bg_image.webp';
const SearchBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const langdata = useSelector(state => state.masterdata.langdata);
    const slectedkeys = useSelector(state => state.masterdata.slectedkeys);
    const { slug } = useParams();
    const location = useLocation();
    const searchBarRef = useRef(null);

    const {
        searchDetail,
        searchValue,
        showsearchdata,
        categoryData,
        selectedItem,
    } = useSelector((state) => state.catlogdetail);

    const { getSearchProduct } = SearchAction();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                dispatch({ type: 'setshowdata', payload: false });
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dispatch]);

    const handleChange = (e) => {
        e.preventDefault();
        const input_data = { search: e.target.value };
        dispatch({ type: 'setValue', payload: e.target.value });
        dispatch(getSearchProduct(input_data));
    };

    const handleKeyDown = (e) => {
        e.preventDefault();
        dispatch({ type: 'setValue', payload: e.target.value });

        const input_data = { search: e.target.value };

        if (e.key === 'Enter') {
            dispatch({ type: 'setshowdata', payload: false });
            navigate(`/search-result/${searchValue}`);
        }
    };

    useEffect(() => {
        if (location.pathname === `/search-result/${slug}`) {
            dispatch({ type: 'setValue', payload: slug });
        }
    }, [slug, dispatch, location.pathname]);

    const searchButton = () => {
        if (searchValue !== '') {
            dispatch({ type: 'setshowdata', payload: false });
            navigate(`/search-result/${searchValue}`);
        }
    };

    const selected = (eventKey) => {
        dispatch({ type: 'setSelectedItem', payload: eventKey });
    };

    const expand = () => {
        dispatch({ type: 'setshowdata', payload: true });
    };

    const close = () => {
        dispatch({ type: 'setshowdata', payload: false });
    };

    const handleMouseEnter = () => {
        dispatch({ type: 'setshowdata', payload: true });
    };

    const handleMouseLeave = () => {
        dispatch({ type: 'setshowdata', payload: false });
    };
    return (
        <div
            ref={searchBarRef}
            // onFocus={expand}
            // onBlur={close}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tabIndex="0"
        >
            <InputGroup>
                <InputGroup.Text id="basic-addon1">
                    <NavDropdown title={selectedItem != null && selectedItem.split('@')[0] || "All"} id="nav-dropdown-search" onSelect={selected}>
                        <NavDropdown.Item eventKey="All categories">
                            All Categories
                        </NavDropdown.Item>
                        {categoryData && categoryData.map((ele, index) => (
                            <NavDropdown.Item key={index} eventKey={`${ele.name}@${ele.id}`} className="search_cat_dropdown">
                                {ele.name}
                            </NavDropdown.Item>
                        ))}
                    </NavDropdown>
                </InputGroup.Text>
                <Form.Control
                    placeholder=  {slectedkeys != '' && (langdata.length > 0) ? (
                        langdata
                            .filter(comp => comp.KEYS === "navlink_search")
                            .map(translatedData => (
                    
                                        <span key={translatedData.id}>
                                            {translatedData[slectedkeys]}
                                        </span>
                                       
                            ))
                    ) : "Search"

    }
                    aria-label="Username"
                    size="md"
                    defaultValue={searchValue}
                    onChange={handleChange}
                    onKeyUp={handleKeyDown}
                />
                <InputGroup.Text id="search-button" onClick={searchButton}>
                    <AiOutlineSearch />
                </InputGroup.Text>
            </InputGroup>

            {showsearchdata && searchDetail.length > 0 && (
               
                <div
                    className="searchDetail"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                > 
                    {searchDetail.length > 0 ? (
                        searchDetail.map((ele, index) => {
                            const detail_url = ele.variantList.length > 0 ? `${ele.slug}?variant=${ele.variantList[0].variantid}` : ele.slug;
                            return (
                                <div
                                    key={index}
                                    className="text-decoration-none"
                                    onClick={() => {
                                        navigate(`/product/${detail_url}`);
                                        dispatch({ type: 'setshowdata', payload: false });
                                    }}
                                >
                                    <div className="searchbar-product-list">
                                        {ele.product_images.length > 0 && <img referrerPolicy="no-referrer"  src={ele.product_images[0]} alt={ele.product_title} 
                                           onError={e => {
                                            e.currentTarget.src = logo
                                          }}
                                        />}
                                        <h6 className="search-title-products">{ele.product_title}</h6>
                                    </div>
                                </div>
                            );
                        })
                    ) : 
                        <div className="searchbar-product-list">No Record Found</div>
                    }
                </div>
            )}
        </div>
    );
};

export default SearchBar;
