import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import avatar from '../../images/avatar.webp';
import { IoMdNotifications } from 'react-icons/io'
import { FaMapMarkerAlt, FaPowerOff } from 'react-icons/fa';
import { BsPersonCircle, BsFillBoxFill, BsFillCaretUpFill, BsHeartFill } from 'react-icons/bs'
import SearchBar from './SearchBar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Modal } from 'react-bootstrap';
import { ModalAddressList } from '../common';
import { FiShoppingCart } from "react-icons/fi";
import { GoPerson } from "react-icons/go";
import logo from '../../images/bg_image.webp';
import { useSelector, useDispatch } from 'react-redux';
import { useCookie } from '../../hooks';

const Header = React.memo(() => {
  const { deleteCookie } = useCookie()
  const [selectedOption, setSelectedOption] = useState(null);
    const langdata = useSelector(state => state.masterdata.langdata);
    const slectedkeys = useSelector(state => state.masterdata.slectedkeys);
    const keysdata = langdata  && langdata[0]
        ? Object.keys(langdata[0])
        : [];
  const dispatch = useDispatch()
  const cartDetail = useSelector(state => state.cartdetail.cartDetail);
  const authStatus = useSelector(state => state.masterdata.authStatus);
  const addressList = useSelector(state => state.addressdata.addressList);
  const openaddressModal = useSelector(state => state.addressdata.openaddressModal);
  const userData = useSelector(state => state.masterdata.userData);
  const [addDetail, setAddDetail] = useState([])
  const clicked = (data) => {
    // alert(data)
    dispatch({
        type: 'setslectedkeys',
        payload: data

    });
    setSelectedOption(data);
}
  useEffect(() => {
    if (addressList != undefined) {
      const add = addressList.filter(ele => ele.default == 1);
      setAddDetail(add);
    }
  }, [addressList])

  const handleClose = () => {
    dispatch({ type: 'setopenaddressModal', payload: !openaddressModal })
  }

  return (

    <div className="header" >
      <div className='header-item-left'>
        <div className="logo" >
          <Link to="/">
            {
              <img referrerPolicy="no-referrer" src={localStorage.getItem('company_logo') != null && localStorage.getItem('company_logo') }  
              onError={e => {
                e.currentTarget.src = logo
              }}
              />
            }
          </Link>
        </div>

        <div onClick={() => dispatch({ type: 'setopenaddressModal', payload: true })} className="text-decoration-none">
          {
            addDetail.length > 0 &&
            <div className='header-address'>
              <div className='location-marker'>
                <FaMapMarkerAlt size="15" />
              </div>
              {
                addDetail.map((ele, index) => {
                  return (
                    <div className='header-address-lines' key={index}>
                      <div className='header-address-line1'>Deliver to {ele.name}</div>
                      <div className='header-address-line2'>{ele.state} ,{ele.pincode}</div>
                    </div>
                  )
                })
              }

            </div>
          }



        </div>


      </div>


      <div className='searchbar'  >
        <SearchBar />
      </div>
      <div>
      {
                        keysdata.length > 0  &&


                        <NavDropdown title={`  ${selectedOption || 'ENGLISH'}`}>
                        {
                            keysdata.slice(1,keysdata.length).map(ele => (
                                <NavDropdown.Item key={ele} onClick={() => clicked(ele)}>
                                    {ele}
                                </NavDropdown.Item>
                            ))

                        }

                    </NavDropdown>
                    }
      </div>

      <div className='header-item-right'>
        <div className='ms-4 my_Account_header' style={{ display: "flex" }}>
          <GoPerson size="40" className="me-2 icon_color" />
          <div>
            <div className='dropdown-up-title'>
            {slectedkeys != '' && (langdata.length > 0) ? (
                                        langdata
                                            .filter(comp => comp.KEYS === "header_navlink_hello")
                                            .map(translatedData => (
                                    
                                                        <span key={translatedData.id}>
                                                            {translatedData[slectedkeys]}
                                                        </span>
                                                       
                                            ))
                                    ) : "Hello"

                    }, {authStatus ? userData.name : slectedkeys != '' && (langdata.length > 0) ? (
                      langdata
                          .filter(comp => comp.KEYS === "navlink_ sign in")
                          .map(translatedData => (
                  
                                      <span key={translatedData.id}>
                                          {translatedData[slectedkeys]}
                                      </span>
                                     
                          ))
                  ) : "sign in"

   }</div>
            <NavDropdown
              id="nav-dropdown-dark-example"
              title=    {slectedkeys != '' && (langdata.length > 0) ? (
                langdata
                    .filter(comp => comp.KEYS === "navlink_Accountandlist")
                    .map(translatedData => (
            
                                <span key={translatedData.id}>
                                    {translatedData[slectedkeys]}
                                </span>
                               
                    ))
            ) :  "Account & Lists"

}
              
              
              
              
             
            >


              {authStatus ?
                <>
                  <NavDropdown.Item >
                    <NavLink to={"/my-account"} className="text-decoration-none">

                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon'> <BsPersonCircle /> </div>
                        <div className='Profile-Dropdown-name'>My Account</div>
                      </div>
                    </NavLink>

                  </NavDropdown.Item>

                  <NavDropdown.Item >
                    <NavLink to={"/my-orders"} className="text-decoration-none">
                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon'>  <BsFillBoxFill /></div>
                        <div className='Profile-Dropdown-name'>Orders</div>
                      </div>
                    </NavLink>
                  </NavDropdown.Item>

                  <NavDropdown.Item >
                    <NavLink to={"/wishlist"} className="text-decoration-none">
                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon'>    <BsHeartFill /></div>
                        <div className='Profile-Dropdown-name'>Wishlist</div>
                      </div>
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item >
                    <div className='Profile-Dropdown'>
                      <div className='Profile-Dropdown-icon'>  <IoMdNotifications /> </div>
                      <div className='Profile-Dropdown-name'>Notifications</div>
                    </div>

                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={deleteCookie}>
                    <div className='Profile-Dropdown'>
                      <div className='Profile-Dropdown-icon'>  <FaPowerOff /> </div>
                      <div className='Profile-Dropdown-name'>Sign Out</div>
                    </div>

                  </NavDropdown.Item>
                  <div className='Profile-Dropdown-icon-arrow'><BsFillCaretUpFill /></div>
                </>
                :

                <NavDropdown.Item >

                  <NavLink to={"/login"} className="text-decoration-none">
                    <div className='Profile-Dropdown'>
                      <div className='Profile-Dropdown-icon'>  <FaPowerOff /></div>
                      <div className='Profile-Dropdown-name'>Sign In</div>
                    </div>
                  </NavLink>

                </NavDropdown.Item>

              }

            </NavDropdown>
          </div>

        </div>

        <Link to="/cart" className='text-decoration-none'>
          <div className='cart'>
            <div>
              <FiShoppingCart
                className="cartIcon "
                size="25"
              />{" "}
              <span className="custom-badge">{cartDetail.result.filter(ele => ele.cart_status == 1).length}</span>
            </div>
          </div>
        </Link>


      </div>

      <Modal
        show={openaddressModal}
        size="sm"
        className='my-custom-modal'
        centered
        onHide={() => handleClose()}
      >

        <Modal.Body>
          <ModalAddressList />
        </Modal.Body>
      </Modal>

    </div>

  );
});

export default Header;