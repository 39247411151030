const initialState = {
  loaderstatus: false,
  loaderstatus1: false,
  otpform:0,
  updatedProfilephone:{},
  otpToken:' ',
  openModal:false,
  authStatus: false,
  userData: {},
  subscription:[],
  HomePageData: [],
  vendor_token:'',
  profileData:[],
  categoryData: [],
  updatedProfilephone:{},
  sidebar: false,
  masterData: {},
  profileData:[],
  error: null,
  categoryOpen: 'level1',
  presntcategoryOpen: "",
  presntsubcategoryOpen: "",
  step:1,
  pwd_Email:"",
  user:null,
  footerdata:[],
  carusolesmall:2.3,
  carusolemedium:3.3,
  carusolelarge:4,
  carusoleextralarge:6,
  ratingdata:{},
  langdata:[],
  slectedkeys:'',
  productslug:"",
  

};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'setproductslug':
      return {
        ...state,
        productslug: action.payload
      };
    case 'setslectedkeys':
      return {
        ...state,
        slectedkeys: action.payload
      };
      case 'setlangdata':
        return {
          ...state,
          langdata: action.payload
        };
    case 'setRatingdata':
      return {
        ...state,
        ratingdata: action.payload
      }
 
    case 'setFooterdata':
      return {
        ...state,
        footerdata: action.payload
      };
    case 'setuser':
      return {
        ...state,
        user: action.payload
      };
    case 'setpwd_Email':
      return {
        ...state,
        pwd_Email: action.payload
      };
    case 'setStep':
      return {
        ...state,
        step: action.payload
      };
    case 'setSubscription':
      return {
        ...state,
        subscription: action.payload
      };
    case 'setupdatedProfilephone':
      return {
        ...state,
        updatedProfilephone: action.payload
      };
    case 'setopenModal':
      return {
        ...state,
        openModal: action.payload
      };
    case 'setotpToken':
      return {
        ...state,
        otpToken: action.payload
      };
    case 'setotpform':
      return {
        ...state,
        otpform: action.payload
      };
    case 'setLoaderstatus':
      return {
        ...state,
        loaderstatus: action.payload
      };
    case 'setLoaderstatus1':
      return {
        ...state,
        loaderstatus1: action.payload
      };
    case 'homepage_detail':
      return {
        ...state,
        HomePageData: action.payload
      };
    case 'masterData':
      return {
        ...state,
        masterData: action.payload
      };
    case 'categorylistData':
      return {
        ...state,
        categoryData: action.payload
      };
    case 'setsidebar':
      return {
        ...state,
        sidebar: action.payload
      };
    case 'setcategoryOpen':
      return {
        ...state,
        categoryOpen: action.payload
      };
      case 'setprofileData':
        return {
          ...state,
          profileData: action.payload
        };
    case 'setauthStatus':
      return {
        ...state,
        authStatus: action.payload
      };
    case 'setuserData':
      return {
        ...state,
        userData: action.payload
      };
    case 'setpresentcategoryOpen':
      return {
        ...state,
        presntcategoryOpen: action.payload
      };
    case 'setpresentsubcategoryOpen':
      return {
        ...state,
        presntsubcategoryOpen: action.payload
      };
    case 'setprofileData':
      return {
        ...state,
        profileData: action.payload
      };
    case 'setvendor_token':
      return {
        ...state,
        vendor_token: action.payload
      };
    case 'setloader':
      return {
        ...state,
        loaderstatus: action.payload
      };
    case 'setupdatedProfilephone':
      return {
        ...state,
        updatedProfilephone: action.payload
      };
    default:
      return state;
  }
};
