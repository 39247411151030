import React, { useState, useContext, useEffect } from 'react';
import { InputBox, AddressAutoComplete } from '../common';
import { Row, Col, } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import AddressAction from '../../redux/actions/AddressAction';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import validate from '../../validations/FormValidationRules';
const AddressForm = ({ type, showbtn }) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const address = useSelector(state => state.addressdata.address);
    const onchangeadress = useSelector(state => state.addressdata.onchangeadress);
    const errors = useSelector(state => state.addressdata.errors);
    const geoaddress = useSelector(state => state.addressdata.geoaddress);
    const formValues = useSelector(state => state.addressdata.formValues);
    const addressData = useSelector(state => state.addressdata.addressData);
    const profileData = useSelector(state => state.masterdata.profileData);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` })
    useEffect(() => {
        if (addressData.length > 0) {

            dispatch({ type: 'setAddres', payload: addressData[0].address })
            dispatch({ type: 'setFormValues', payload: addressData[0] })
        } else {
            dispatch({
                type: 'setFormValues', payload: {
                    ...formValues,
                    name: profileData.length > 0 ? profileData[0].name : '',
                    phone: profileData.length > 0 ? profileData[0].phone : '',
                    // mailid: profileData.length > 0 ? profileData[0].mailid : '',
                }
            })
        }
    }, [addressData, profileData])


    useEffect(() => {
        if (address != '') {

            dispatch({
                type: 'setFormValues', payload: { ...formValues, ["address"]: address }
            })
        }
    }, [address])



    useEffect(() => {
        if (onchangeadress != '') {
            if (address != '') {
                var pincode = geoaddress[0].address_components.filter(ele => ele.types.includes("postal_code"))
                var state_name = geoaddress[0].address_components.filter(ele => ele.types.includes("administrative_area_level_1"))

                var HNO = '';
                if (geoaddress[0].address_components.some(ele => ["route", "subpremise", "premise"].some(type => ele.types.includes(type)))) {
                    var filteredComponents = geoaddress[0].address_components.filter(ele => ["route", "subpremise", "premise"].some(type => ele.types.includes(type)));
                    HNO = filteredComponents.length > 0 ? filteredComponents : '';
                }




                var Area = geoaddress[0].address_components.filter(ele => ele.types.includes("political"))
                var Country = geoaddress[0].address_components.filter(ele => ele.types.includes("country"))
                var city = geoaddress[0].address_components.filter(ele => ele.types.includes("locality"))
                dispatch({
                    type: 'setFormValues', payload: {
                        ...formValues,
                        // address: address,
                        pincode: pincode.length > 0 ? pincode[0].short_name : '',
                        state: state_name.length > 0 ? state_name[0].long_name : '',
                        address1: HNO.length > 0 ? HNO[0].long_name : '',
                        address2: Area.length > 0 ? Area[0].long_name : '',
                        country: Country.length > 0 ? Country[0].long_name : '',   
                        city: city.length > 0 ? city[0].long_name : '',
                    }
                })
            } else {
                dispatch({
                    type: 'setFormValues', payload: {
                        ...formValues,
                        name: profileData.length > 0 ? profileData[0].name : '',
                        phone: profileData.length > 0 ? profileData[0].phone : '',
                        // mailid: profileData.length > 0 ? profileData[0].mailid : '',
                        // HNO:HNO,
                        // Area:Area,
                    }
                })
            }
        }
    }, [onchangeadress])


    const { addAddress } = AddressAction();

    const handleChange = (event) => {
        event.persist();
        dispatch({
            type: 'setFormValues', payload: { ...formValues, [event.target.name]: event.target.value }
        })
    };
    useEffect(() => {
        if ((Object.keys(errors).length === 0) && isSubmitting) {
            dispatch(addAddress(formValues, location.pathname))
        }
    }, [errors]);


    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        dispatch({
            type: 'setErrors', payload: validate(formValues)
        })
        setIsSubmitting(true);
    };


    return (
        <div className={type != 3 ? 'checkout-address-form' : 'checkout-paymentmethod-address-form'}>
            <div className='checkout-address-form-title mb-4'>
                {(type != 3) && ((addressData.length > 0) ? "Edit Address" : "Add new Address")}
            </div>
            {
                formValues != undefined &&
                <div className='address-form'>
                    <Row>


                        <Col lg={6}>
                            <InputBox label={"Name"} id={"name"} handleChange={handleChange} errors={errors} value={formValues.name} />
                        </Col>
                        <Col lg={6}>
                            <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} value={formValues.phone} />
                        </Col>
                        {/* <Col lg={6}>
                            <InputBox label={"Email"} id={"mailid"} handleChange={handleChange} errors={errors} value={formValues.mailid}/>
                        </Col> */}
                        <Col lg={12}>




                            <AddressAutoComplete errors={errors} />





                            {/* <TextArea label={"Address"} id={"address"} handleChange={handleChange} errors={errors} value={formValues.address} /> */}
                        </Col>
                        <Col lg={6}>
                            <InputBox label={"H.NO, Building,Flat"} id={"address1"} handleChange={handleChange} errors={errors} value={formValues.address1} />
                        </Col>
                        <Col lg={6}>
                            <InputBox label={"Area, Street, Sector, Village"} id={"address2"} handleChange={handleChange} errors={errors} value={formValues.address2} />
                        </Col>
                        <Col lg={6}>
                            <InputBox label={"City"} id={"city"} handleChange={handleChange} errors={errors} value={formValues.city} />
                        </Col>

                        <Col lg={6}>
                            <InputBox label={"State"} id={"state"} handleChange={handleChange} errors={errors} value={formValues.state} />
                        </Col>
                        <Col lg={6}>
                            <InputBox label={"Postal Code"} id={"pincode"} handleChange={handleChange} errors={errors} value={formValues.pincode} />
                        </Col>
                        <Col lg={6}>
                            <InputBox label={"Country"} id={"country"} handleChange={handleChange} errors={errors} value={formValues.country} />
                        </Col>
                        {
                            showbtn && <div className='product-detail-buttongroup address-sav-btn' >
                                <div className={`product-detail-button`} onClick={handleSubmit}>
                                    Save and Update Here
                                </div>

                                <div className={`product-detail-button btn2`}
                                    onClick={() => {
                                        dispatch({ type: 'setopenform', payload: false })
                                    }
                                    }>
                                    cancel
                                </div>
                            </div>
                        }

                    </Row>
                    {
                        isDesktoporMobile &&
                        <Row>
                            <div className='buttons-product-detaildiv' onClick={handleSubmit}>
                                <div className='product-detail-buttongroup'>
                                    <div className='product-detail-button text-decoration-none'>
                                        Save and Deliver Here
                                    </div>
                                </div>
                            </div>

                        </Row>
                    }
                </div>
            }

        </div>
    );
};

export default AddressForm;