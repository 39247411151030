import React, { useState, useContext, useEffect } from 'react';
import {
    Form,
} from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { AddressForm } from '../common';
import AddressAction from '../../redux/actions/AddressAction';
import { useDispatch,useSelector } from 'react-redux';


const AddressList = React.memo(({ type, showbtn, addressList }) => {
    const dispatch = useDispatch()
    const onchangeadress = useSelector(state => state.addressdata.onchangeadress);
    const [checked, setchecked] = useState();

    const { deleteAddress } = AddressAction();

    const openform = useSelector(state => state.addressdata.openform);
    const addressData = useSelector(state => state.addressdata.addressData);

    useEffect(() => {
        if (addressList.length > 0) {
            var io = addressList.filter(ele => ele.default == 1);
            if (io.length > 0) {
                setchecked(io[0].addressId)
            }
        }
    }, [addressList])


    const handleEdit = (addressId) => {
        // dispatch({ type: 'setErrors', payload: {} })
        dispatch({ type: 'setonchangeadress', payload: '' })
        dispatch({ type: 'setopenform', payload: true })
        dispatch({ type: 'setaddressData', payload: addressList.filter(ele => ele.addressId == addressId) })
        dispatch({ type: 'setAddres', payload:'' })
        setchecked(addressId)
    }

    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteAddress(input_data))
    }


    return (
        <>
            {
                addressList.filter(elefilter => elefilter.addresstype == 'Delivery').length > 0 ? addressList.filter(elefilter => elefilter.addresstype == 'Delivery').map(ele => {
                    return (
                        <>
                            {
                                ((addressData.length > 0) && (openform) && (addressData[0].addressId == ele.addressId)) ?
                                    <>
                                        <AddressForm type={type} showbtn={showbtn} addressData={addressData} />
                                        <div className='border-bottom'></div>
                                    </>
                                    :
                                    <div className='accordion-address-body-listitem' >
                                        <div className="d-flex justify-content-between" >
                                            <div onClick={() => handleEdit(ele.addressId)}>
                                                <Form.Check
                                                    type="radio"
                                                    name="addressType"
                                                    value={ele.addressId}
                                                    label={ele.name}
                                                    onChange={(e) => setchecked(e.target.value)}
                                                    checked={checked == ele.addressId}
                                                    // defaultChecked={each.default_address ? true : false}
                                                    aria-label="radio 1"
                                                />
                                            </div>
                                            <div className="d-flex justify-content-end addressIcon">
                                                <BiPencil
                                                    size={20}
                                                    className="me-1"
                                                    onClick={() => handleEdit(ele.addressId)}
                                                />
                                                <AiOutlineDelete
                                                    color=""
                                                    size={20}
                                                    onClick={(e) => clicked('address', ele.addressId)}
                                                />
                                            </div>
                                        </div>
                                        <div className='checkout-list-address'>
                                            {ele.address}
                                            <div>Hyderabad, {ele.state} - <span>{ele.pincode}</span></div>


                                        </div>
                                    </div>
                            }

                        </>
                    )
                })
                    :
                    <AddressForm type={type} showbtn={showbtn} addressData={addressData} />
            }

        </>

    );
});

export default AddressList;