import React, { useContext, useEffect, useState } from 'react';
import { PaymentRazarpay, CartList, CartPaymentMethod, PickupAddressList, Loder, MobileHeader1, CHeckoutPaymentMethod, CheckotDeliverySlot, CHeckoutAddress, AddressForm, BillingAddressForm, ReactToast, PaymentMethodComp } from '../common';
import Sheet from 'react-modal-sheet';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { BsXLg } from 'react-icons/bs';
import { Row, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import AddressAction from '../../redux/actions/AddressAction';
const MobileCheckout = () => {
    const dispatch = useDispatch()
    const razzer = useSelector(state => state.orderdetail.razzer);

    const userData = useSelector(state => state.masterdata.userData);
    const masterData = useSelector(state => state.masterdata.masterData);
    const bottomsheet = useSelector(state => state.cartdetail.bottomsheet);
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const addressList = useSelector(state => state.addressdata.addressList);
    const deliverdatetime = useSelector(state => state.addressdata.deliverdatetime);
    const deliverslot = useSelector(state => state.addressdata.deliverslot);
    const billingformValues = useSelector(state => state.addressdata.billingformValues);
    const addressData = useSelector(state => state.addressdata.addressData);
    const addressOpen = useSelector(state => state.addressdata.addressOpen);

    const { getAddressList } = AddressAction();
    useEffect(() => {
        dispatch(getAddressList());
    }, [])

    useEffect(() => {
        if (addressList != undefined && addressList.length > 0) {
            var listadd = addressList.filter(ele => ele.addresstype == 'Delivery')
            if (listadd.length > 0) {
                dispatch({
                    type: 'setFormValues', payload: addressList.filter(ele => ele.default == 1)[0]
                })
            }
            if ((userData.hasOwnProperty("cust_type") && userData.cust_type == "2")) {
                if (addressList.filter(ele => ele.addresstype == 'Delivery').length > 0) {
                    dispatch({
                        type: 'setaddressData', payload: addressList.filter(elefilter => elefilter.addresstype == 'Billing')
                    })
                }
                var billing_add = addressList.filter(ele => ele.addresstype == 'Billing')[0]
                dispatch({
                    type: 'setbillingFormValues', payload: {
                        billingname: billing_add.name,
                        billingphone: billing_add.phone,
                        billingaddress: billing_add.address,
                        billingpincode: billing_add.pincode,
                        billingstate: billing_add.state,
                        addressId: "0",
                        addresstype: "Billing"
                    }
                })
            }
        }
    }, [addressList])

    useEffect(() => {
        if ((userData.hasOwnProperty("cust_type"))) {
            if (addressList != undefined && (addressList.length <= 0 || addressList.filter(ele => ele.addresstype == 'Delivery').length <= 0)) {
                dispatch({
                    type: 'setAddressopen', payload: 2
                })
            }
        }
    }, [userData, addressList])

    const openDeliverySlot = () => {
        dispatch({
            type: 'setdeliverslot', payload: true
        })
    }
    const [checked, setchecked] = useState(1);

    return (
        <div className='mobilecheckout'>
            {
                addressList != undefined && addressList.length > 0 &&
                <>
                    <div className='delivery_Address' style={{ padding: "15px 15px 0px 15px" }}>
                        <div className='delivery_Address-header'>

                            {
                                Object.keys(masterData).length > 0 && masterData.vendor.hasOwnProperty("pickup") ?
                                    <div>
                                        <Form.Check
                                            type="radio"
                                            name="addressSelect"
                                            value={1}
                                            label="Delivery Address"
                                            onChange={(e) => setchecked(e.target.value)}
                                            checked={checked == 1}
                                            // defaultChecked={each.default_address ? true : false}
                                            aria-label="radio 1"
                                        />
                                    </div>


                                    :



                                    <div>Delivery Address </div>}
                            <div className='change' onClick={() => {
                                dispatch({
                                    type: 'setAddressopen', payload: 1
                                })
                            }}>change</div>
                        </div>
                        <div className='delivery_Address-name'>
                            {addressList[0].name}
                        </div>
                        <div className='delivery_Address-line2'>
                            {addressList[0].address1} <br />
                            {addressList[0].address2} <br />
                            {addressList[0].state + " , " + addressList[0].pincode}
                        </div>
                        <div className='delivery_Address-number'>
                            {addressList[0].phone}
                        </div>
                    </div>

                    {
                        Object.keys(masterData).length > 0 && masterData.vendor.hasOwnProperty("pickup") &&
                        <div className='delivery_Address mobile-checkout-divider'>
                            <div className='delivery_Address-header'>

                                <div>
                                    <Form.Check
                                        type="radio"
                                        name="addressSelect"
                                        value={2}
                                        label="Store Pickup"
                                        onChange={(e) => setchecked(e.target.value)}
                                        checked={checked == 2}
                                        // defaultChecked={each.default_address ? true : false}
                                        aria-label="radio 1"
                                    />
                                </div>






                                {/* <div>Delivery Address </div> */}
                                <div className='change' onClick={() => {
                                    dispatch({
                                        type: 'setAddressopen', payload: 11
                                    })
                                }}>change</div>
                            </div>
                            <div className='delivery_Address-name'>
                                {addressList[0].name}
                            </div>
                            <div className='delivery_Address-line2'>
                                {addressList[0].address}
                            </div>
                            <div className='delivery_Address-number'>
                                {addressList[0].phone}
                            </div>
                        </div>
                    }



                    {
                        ((userData.hasOwnProperty("cust_type") && userData.cust_type == "2")) && addressData.length > 0 &&
                        <div className='delivery_Address mobile-checkout-divider'>
                            <div className='delivery_Address-header'>
                                <div>Billing Address </div>
                                <div className='change' onClick={() => {
                                    dispatch({
                                        type: 'setAddressopen', payload: 5
                                    })
                                }}>change</div>
                            </div>
                            <div className='delivery_Address-name'>
                                {billingformValues.billingname}
                            </div>
                            <div className='delivery_Address-line2'>
                                {billingformValues.billingaddress}
                            </div>
                            <div className='delivery_Address-number'>
                                {billingformValues.billingphone}
                            </div>
                        </div>
                    }

                    {
                        Object.keys(masterData).length > 0 && masterData.vendor.hasOwnProperty("delivery") &&
                        <div className='mobile-checkout-divider'>
                            <div className="mobile-checkout-deliheading">Schedule Order</div>
                            <div className='mobile-checkout-selectslot-heading' onClick={openDeliverySlot}>
                                <div>
                                    {
                                        deliverdatetime.hasOwnProperty("time") ?
                                            deliverdatetime.time
                                            :
                                            "Select Delivery Slot"
                                    }
                                </div>
                                <div><MdOutlineKeyboardArrowDown size={25} style={{ marginTop: "-5px" }} /></div>
                            </div>
                            <Sheet isOpen={deliverslot} disableDrag={"yes"}
                            >
                                <Sheet.Container className='custom-cart-modalsheet'>
                                    <Sheet.Content>
                                        <div className='payment_method border-none'>
                                            <div className='justify-space-between d-flex'>
                                                <div className='payment_header'>Delivery Options</div>
                                                <BsXLg onClick={() => {
                                                    dispatch({
                                                        type: 'setdeliverslot', payload: !deliverslot
                                                    })
                                                }
                                                } />
                                            </div>
                                            <CheckotDeliverySlot type={"mobile"} />
                                        </div>


                                    </Sheet.Content>
                                </Sheet.Container>
                                <Sheet.Backdrop />
                            </Sheet>
                        </div>
                    }

                    <div className='mobile-checkout-divider delivery_Address'>
                        <div className='delivery_Address-header'>
                            <div>Order Summary </div>
                        </div>
                        <CartList type={"checkout"} />
                    </div>
                    <div className='mobile-checkout-divider delivery_Address'>
                        <div className='delivery_Address-header'>
                            <div>Payment Summary </div>
                        </div>
                        <div className='payment_method'>
                            <PaymentMethodComp />
                        </div>
                    </div>
                    <CHeckoutPaymentMethod addressData={addressData} />
                    {
                        razzer.length > 1 ?
                            <div className='mobile-checkout-divider delivery_Address'>

                                <div className='delivery_Address-header'>
                                    <div>Payment Method </div>
                                </div>

                                <div className='payment_method'>

                                </div>
                            </div>
                            :
                            <PaymentRazarpay comptype={"mobile"} />
                    }

                    <Row>

                        <Sheet isOpen={bottomsheet} onClose={() => {
                            dispatch({
                                type: 'setbottomsheet', payload: false
                            })
                        }}
                        >
                            <Sheet.Container className='custom-cart-modalsheet'>
                                <Sheet.Content>
                                    <CartPaymentMethod type={"dropdown"} />
                                    <div className={`product-detail-buttongroup${!bottomsheet ? 'cart-bottomsheet-active' : ''}`}>
                                        <div className='cart-detail-payment' onClick={() => {
                                            dispatch({
                                                type: 'setbottomsheet', payload: !bottomsheet
                                            })
                                        }}>
                                            {localStorage.getItem("currency") + cartDetail.total} {!bottomsheet ? <BiChevronDown size="20" color="#ff7e00" /> : <BiChevronUp size="20" color="#ff7e00" />}
                                        </div>
                                        <div className='product-detail-button'>
                                            Complete Payment
                                        </div>
                                    </div>
                                </Sheet.Content>
                            </Sheet.Container>
                            <Sheet.Backdrop />
                        </Sheet>
                        <Sheet isOpen={addressOpen != 0} disableDrag={"yes"}
                        >
                            <Sheet.Container className='custom-checkout-address-modalsheet' >
                                <Sheet.Content >
                                    <MobileHeader1 />
                                    {/* {
                                        loaderstatus &&
                                        <div className='main-backdrop main-backdrop-extra'></div>
                                    } */}
                                    {
                                        addressOpen == 1 &&
                                        <>
                                            <div className='question'>
                                                <div className='custom-checkout-accordionbody'>
                                                    <CHeckoutAddress />
                                                </div>
                                            </div>
                                        </>

                                    }
                                    {
                                        addressOpen == 2 &&
                                        <>
                                            <div className='question' style={{ border: "none" }}>
                                                <AddressForm addressData={[]} />
                                            </div>
                                            <ReactToast />
                                        </>
                                    }
                                    {
                                        addressOpen == 3 &&
                                        <>
                                            <div className='question' style={{ border: "none" }}>
                                                <AddressForm addressData={addressData} />
                                            </div>
                                            <ReactToast />
                                        </>
                                    }
                                    {
                                        addressOpen == 11 &&
                                        <>
                                            <div className='question' style={{ border: "none" }}>
                                                <div className='custom-checkout-accordionbody'>
                                                    <PickupAddressList type={1} />
                                                </div>
                                            </div>
                                            <ReactToast />
                                        </>
                                    }
                                    {
                                        addressOpen == 4 &&
                                        <>
                                            <div className='question' style={{ border: "none" }}>
                                                <CHeckoutPaymentMethod addressData={addressData} />
                                            </div>
                                            <ReactToast />
                                        </>
                                    }
                                    {
                                        addressOpen == 5 &&
                                        <>
                                            <div className='question' style={{ border: "none" }}>
                                                <BillingAddressForm addressData={addressData} type={3} />
                                            </div>
                                            <ReactToast />
                                        </>
                                    }
                                </Sheet.Content>
                                <Loder />
                            </Sheet.Container>
                            <Sheet.Backdrop />
                        </Sheet>
                    </Row>
                </>
            }
            {
                (addressList != undefined && addressList.length <= 0 && userData.hasOwnProperty("cust_type") && userData.cust_type == "1") &&
                <Sheet isOpen={true} disableDrag={"yes"}
                >
                    <Sheet.Container className='custom-checkout-address-modalsheet' >
                        <Sheet.Content >
                            <MobileHeader1 />
                            {/* {
                                loaderstatus &&
                                <div className='main-backdrop main-backdrop-extra'></div>
                            } */}

                            {
                                addressOpen == 2 &&
                                <>
                                    <div className='question' style={{ border: "none" }}>
                                        <AddressForm addressData={[]} />
                                    </div>
                                    <ReactToast />
                                </>
                            }
                        </Sheet.Content>
                        <Loder />
                    </Sheet.Container>
                    <Sheet.Backdrop />
                </Sheet>

            }

        </div>
    );
};

export default MobileCheckout;