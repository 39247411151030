import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import logo from '../../images/bg_image.webp';
const Banner2 = ({ carouselCompData }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const [first, second] = carouselCompData.sectionResult
        .map(ele => ele)
        .sort((a, b) => a.id - b.id);

    // const [first, second, third,fourth] = brands
    return (
        <div>
            <Container fluid>


                <Row className='brand32-main '>

                    <Col lg={6} md={6} sm={12} xs={12} className='brands-col-maindiv'>
                        <NavLink to={first.url}>
                            <div className='single-banner home-fullbanner '>
                                {
                                    !isMobile ?
                                        <img className='brand-images' src={first.image}
                                        onError={e => {
                                            e.currentTarget.src = logo
                                        }}></img> :
                                        <img className='brand-images' src={first.mobile_image}
                                        onError={e => {
                                            e.currentTarget.src = logo
                                        }}></img>
                                }


                            </div>
                        </NavLink>


                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='brands-col-maindiv'>
                        <NavLink to={second.url}>
                            <div className='single-banner home-fullbanner '>
                                {
                                    !isMobile ?
                                        <img className='brand-images' src={second.image}
                                        onError={e => {
                                            e.currentTarget.src = logo
                                        }}></img> :
                                        <img className='brand-images' src={second.mobile_image}
                                        onError={e => {
                                            e.currentTarget.src = logo
                                        }}></img>
                                }
                            </div>
                        </NavLink>
                    </Col>



                </Row>




            </Container>
        </div>
    );
};

export default Banner2;