import React, { useEffect, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BsFillTagFill, BsStarFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import logo from '../../images/bg_image.webp';
const HomeProductCard = ({ type, item, link,classname }) => {

    const masterData = useSelector(state => state.masterdata.HomePageData);

    const [percentage, setpercentage] = useState('');
    const [original_price, setoriginal_price] = useState(0);

    useEffect(() => {
        if (Object.keys(masterData).length > 0 && masterData.hasOwnProperty("subscription")) {
            setpercentage(parseFloat(masterData.subscription.discount))
            var originalPrice = item.mrp;
            var discountPercentage = masterData.subscription.discount / 100;
            var discountAmount = originalPrice * discountPercentage;
            var finalPrice = originalPrice - discountAmount;
            setoriginal_price(Math.round(finalPrice))
        } else {
            var price = item.mrp;
            var diff = price - item.selling_price;
            var calpercentage = diff / price * 100;
            setpercentage(Math.round(calpercentage))
            setoriginal_price(item.selling_price)
        }

    }, [item, masterData])

    if (item.variantList.length > 0) {
        var detail_url = `${item.slug}?variant=${item.variantList[0].variantid}`;
    } else {
        var detail_url = item.slug;
    }

    return (
        <NavLink to={`${link == 'product-detail' ? '/product/' + detail_url : 'product/' + detail_url}`} className={"text-decoration-none"} style={{ width: "100%" }}>
       
            <div className={`carousel-componet-card ${type == 'homecomponent4' ? "half-carousel-card" : ""}`}>

                <div className={`home-product-card ${type == 'homecomponent4' ? "" : ""}`}>
                    <div className={`home_product_image ${type == 'homecomponent4' ? " " : "home_produt_image_extracss"}`}>

                        <img  referrerPolicy="no-referrer" src={item.product_images.length > 0 && item.product_images[0]} className={type == 'homecomponent4' ? 'img-half-carousel' : ''} 
                         onError={e => {
                            e.currentTarget.src = logo
                          }}/>
                        <div className='percentages-on-products'>
                    {
                        item.mrp != item.selling_price &&
                        <h5 className="ratings">
                            <h5 className="product-ratingsContainer">
                                {percentage}% OFF
                            </h5>
                        </h5>
                    }
                    </div>
                  
                    </div>
                    <div className='home-product-title-maindiv'>
                    <h3 className='home-product-card-title'>{item.product_title}</h3>
                   
                   
                    {
                        item.hasOwnProperty("pricetag") ?
                            <h5 className='home-product-card-title pricetag'>{item.pricetag}</h5>
                            
                            :

                            <div className="product-price"><div className="product-price product-price-revamp"><h5 className="Paragraph-16-M--Semibold">{localStorage.getItem("currency") + original_price}</h5>
                                {
                                    item.mrp != item.selling_price &&
                                    <h5 className="offer offer-revamp Paragraph-16-M--Regular">{localStorage.getItem("currency") + item.mrp}</h5>
                                }
                            </div>
                            </div>
                    }
                    </div>
                   
                </div>
            </div>
         
        </NavLink>


    );
};

export default HomeProductCard;