import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import logo from '../../images/bg_image.webp';
import { BiSolidChevronRight, BiSolidChevronLeft } from "react-icons/bi";
import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
const CarouselComp2 = ({ position, carouselCompData,loopindex, type, link }) => {
    const [my_swiper, set_my_swiper] = useState({});

    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const [index, setindex] = useState(0);

    const slidechange = (e) => {
        setindex(e.realIndex)
    }
    return (
        
           
        carouselCompData.sectionResult.length > 0 &&
        <Row>
          
            <Col lg={12}>

                <div className={`home-carousel-component-header ${link == 'product-detail' ? 'detail-carousel-bottom' : ''}`}>
                    {/* <div className='home-carousel-componentheader-title'>{carouselCompData.section_title}</div> */}
                    <div className='Quality-products'>
                        <h5 className='Quality-products-details-left1'>

                        </h5>
                        <h5 className='Quality-products-details-left2'>
                            {carouselCompData.section_title}
                        </h5>
                    </div>
                    {
                        type == "view-all" &&
                        <h5 className='home-carousel-componentheader-viewall'>View all</h5>
                    }
                </div>
                {
                    (!isMobile && carouselCompData.sectionResult.length > 5) &&
                    <div className={`previous_${loopindex} previous${position} customprevious`}><BiSolidChevronLeft size="30" /></div>
                }
                <Swiper
                    effect="fade"
                    onInit={(ev) => {
                        set_my_swiper(ev)
                    }}
                    breakpoints={{
                        200: {
                            slidesPerView: 2.3,
                        },
                        700: {
                            slidesPerView: 3,
                        },
                        750: {
                            slidesPerView: 4,
                        },
                        1000: {
                            slidesPerView: 5,
                        },
                        1200: {
                            slidesPerView: 5,
                        },
                        1400: {
                            slidesPerView: 5,
                        },
                        1600: {
                            slidesPerView: 5,
                        },
                        1800: {
                            slidesPerView: 5,
                        },
                    }}
                    onSlideChange={(e) => slidechange(e)}
                    navigation={{
                        nextEl: `.next_${position}`,
                        prevEl: `.previous_${position}`,
                    }}
                    freeMode={true}
                    modules={[Navigation, FreeMode, Pagination, Scrollbar, A11y]}
                    className={`mycategorySwiper_${loopindex} mycategorySwiper${position}`}
                >

                    {
                        carouselCompData.sectionResult.map((ele, index1) => {
                            if (ele.variantList.length > 0) {
                                var detail_url = `${ele.slug}?variant=${ele.variantList[0].variantid}`;
                            } else {
                                var detail_url = ele.slug;
                            }
                            return (
                                <SwiperSlide
                                    onClick={() => {
                                        my_swiper.slideToClickedSlide(index1)
                                    }}
                                    key={index1}>
                                    <NavLink className={"text-decoration-none"} to={`/product/${detail_url}`}>
                                        <div className='Quality-product-row2-col-products '>
                                            <div className=' Quality-product-row2-col-image'>
                                                <img referrerPolicy="no-referrer" className=' Quality-product-row2-col-image-details' src={ele.product_images.length > 0 && ele.product_images[0]}
                                                     onError={e => {
                                                        e.currentTarget.src = logo
                                                      }}
                                                ></img>
                                            </div>
                                            <div className=' Quality-product-row2-col-image-details'>
                                                <h5 className='Quality-product-row2-col-image-product'>{ele.subcategory_name}</h5>
                                                <h5 className='Quality-product-row2-col-image-product-name'>{ele.product_title}</h5>
                                            </div>
                                        </div>
                                    </NavLink>
                                    {/* <div className='allcategory-title'><span>{ele.category_name}</span></div> */}
                                </SwiperSlide>

                            )
                        })
                    }

                </Swiper>
                {
                    (!isMobile && carouselCompData.sectionResult.length > 5) &&
                    <div className={`next_${loopindex} next${position}  customnextdetail`}><BiSolidChevronRight size="30" /></div>
                }

            </Col>


        </Row>
            
    );
};

export default CarouselComp2;

