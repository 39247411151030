import React, { useState, useContext, useEffect } from 'react';
import { RiShoppingCart2Fill } from "react-icons/ri";
import { NavLink, useSearchParams } from 'react-router-dom';
import CartAction from '../../redux/actions/CartAction';
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import logo from '../../images/bg_image.webp';
const WishListCard = ({ ele }) => {
    const dispatch = useDispatch()
    const { deleteCart } = CartAction()
    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteCart(input_data,'wishlist'))
    }
    return (
        <>

        {
     
            <div className="product-grid">
               
            <div className="product-image" >
            {/* <NavLink className="text-decoration-none" to={`/product/`}> */}
                
            <NavLink className="text-decoration-none" to={`/product/${ele.slug}`}>
                <div className="image">
                    <img referrerPolicy="no-referrer"  className="img-1" src={ele.img != '' && ele.img} 
                       onError={e => {
                        e.currentTarget.src = logo
                      }}/>
                  
                </div>
                </NavLink>
                <div ><IoCloseCircle  onClick={() => clicked('cart', ele.id)}  className='wishlist-wrong' /></div>
            </div>

        <div className="product-content">
            <NavLink className="text-decoration-none" >
                <h3 className="title">{ele.product_title}</h3>


                <div className='card-variant-title' >{ele.variantname}</div>
                <div className='space-5'></div>
                <div className='product-price'>
                    <div className="product-price product-price-revamp">
                        <div className="Paragraph-16-M--Semibold">{localStorage.getItem("currency") + ele.price}</div>
                        {
                            ele.mrp != ele.price &&
                            <span className="offer offer-revamp Paragraph-16-M--Regular">{localStorage.getItem("currency") + ele.mrp}</span>
                        }
                    </div>
                    {
                        ele.mrp != ele.price &&
                        <div className="offer-label-revamp Paragraph-12-XS--Semibold">{ele.discount}% OFF</div>
                    }
                </div>

                
            </NavLink>
            <div className='space-10'></div>


            <div className='buttons-product-detaildiv product-card-button-div'>
                <div className='product-detail-buttongroup product-card-cart_btn '>





                    <div className={`product-detail-button cart-btn product-card-btn-2`} onClick={() => clicked('move', ele.id)}>
                        <RiShoppingCart2Fill className="product-card-btn-icon" style={{ marginRight: "3px" }} />
                        MOVE TO CART
                    </div>

                </div>
            </div>
        </div>
    </div>
        }
           
        </>
    );
};

export default WishListCard;

