import React from 'react';
import {BsExclamationCircleFill} from 'react-icons/bs'

const TextArea = ({label,id,handleChange,errors,value}) => {
    return (
        <>
         <div className='field-holder'>
            <textarea type="text" id={id} name={id} value={value} className={`custom-input custom-textarea ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} required onChange={handleChange}/>
           
            <label htmlFor={id} className="custom-label">{label}</label>
            {errors.hasOwnProperty(id) && <BsExclamationCircleFill className="error-icon" />}
        </div>
            {errors.hasOwnProperty(id) && <span className='error-text'>{errors[id]}</span>}
        </>
       

        
    );
};

export default TextArea;