import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlinePayment } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import OrderAction from "../../redux/actions/OrderAction";
import logo from '../../images/bg_image.webp';
const PaymentRazarpay = ({ comptype }) => {
  const razzer = useSelector(state => state.orderdetail.razzer);
  const selectedColor = useSelector(state => state.orderdetail.selectedColor);

  const dispatch = useDispatch()

  const { getPaymentMethodlist } = OrderAction();
  const finadata = selectedColor.split("@");

  const handleColorChange = (event) => {
    const [id, payment_method] = event.target.value.split('@');
    dispatch({ type: 'setSelectedColor', payload: event.target.value })
  };

  useEffect(() => {
    dispatch(getPaymentMethodlist());
  }, []);

  useEffect(() => {
    if (razzer.length > 0) {
      dispatch({ type: 'setSelectedColor', payload: razzer[0].id + '@' + razzer[0].payment_method })
    }
  }, [razzer]);

  return (
    <Row>
      {
        razzer.length > 1 &&
        <div className="Razzerpay">
          <div className=''>
            <div className='detail_row_left'>All transactions are secure and encrypted.</div>
          </div>
          <div>
            <div className='payment-razerpay-main'>
              {razzer && razzer.length > 0 && razzer[0] && razzer[0].payment_method === "Razorpay" && (
                <div className="razzerpay-subdiv">
                  <label htmlFor="pay" className="icons-radio">
                    <div>
                      <Row>
                        <Col lg={1} md={1} sm={1} xs={1}>
                          <input
                            className="payment-radio"
                            type="radio"
                            name="color"
                            value={razzer[0].id + '@' + razzer[0].payment_method}
                            id="pay"
                            checked={selectedColor === razzer[0].id + '@' + razzer[0].payment_method}
                            onChange={handleColorChange}
                          />

                        </Col>
                        <Col lg={7} md={5} sm={10} xs={10}>
                          <div className="razzerpay-title">Razorpay Payment Gateway (UPI, Cards & NetBanking)</div>
                        </Col>
                        <Col lg={4} md={5} sm={12} xs={12}>
                          <img referrerPolicy="no-referrer" className="razzer-img" src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/dcdfe7e1d5626b0a1dda.svg" alt="Razzerpay" onError={e => {
                            e.currentTarget.src = logo
                          }} />
                          <img referrerPolicy="no-referrer" className="razzer-img" src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/0169695890db3db16bfe.svg" alt="Card"
                            onError={e => {
                              e.currentTarget.src = logo
                            }} />
                          <img referrerPolicy="no-referrer" className="razzer-img" src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/5e3b05b68f3d31b87e84.svg" alt="NetBanking"
                            onError={e => {
                              e.currentTarget.src = logo
                            }} />
                        </Col>
                      </Row>
                    </div>
                  </label>
                </div>
              )}
             


              {razzer && razzer.length > 0 && razzer[1] && razzer[1].payment_method === "cod" && (

                <label htmlFor="cod" className="icons-radio">
                  <div>
                    <Row>
                      <Col lg={1} md={1}>
                        <input
                          className="payment-radio"
                          type="radio"
                          name="color"
                          id="cod"
                          value={razzer[1].id + '@' + razzer[1].payment_method}
                          checked={selectedColor === razzer[1].id + '@' + razzer[1].payment_method}
                          onChange={handleColorChange}
                        />

                      </Col>
                      <Col lg={11} md={11}>
                        Cash on Delivery (COD)
                      </Col>
                    </Row>
                  </div>
                </label>
              )
              }

            </div>
          </div>
        </div>
      }

    </Row>

  );
};

export default PaymentRazarpay;
