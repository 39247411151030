import React, { useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";

import { StateContext } from '../context/ManageStates';

import { HomeProductCard2 } from '../common';

import { BiSolidChevronRight, BiSolidChevronLeft } from "react-icons/bi";
import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper/modules';


const HomeCarouselCardComponentDup = ({ heading, position, link, type, carouselCompData }) => {
    const [my_swiper, set_my_swiper] = useState({});

    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const [index, setindex] = useState(0);

    const slidechange = (e) => {
        setindex(e.realIndex)
    }
    const { addon } = useContext(StateContext);





    return (
        <Row>
            <Col lg={12}>

                <div className={`home-carousel-component-header carousel-addon-comp ${link == 'product-detail' ? 'detail-carousel-bottom' : ''}`}>
                    {/* <div className='home-carousel-componentheader-title'>{carouselCompData.section_title}</div> */}
                    <div className='Quality-products'>
                        <h5 className='Quality-products-details-left1'>

                        </h5>
                        <h5 className='Quality-products-details-left2'>
                            {heading} {addon.filter(ele1 => ele1.heading == heading).length > 0 && <span style={{color:"black"}}>( {localStorage.getItem("currency") + addon.filter(ele1 => ele1.heading == heading).reduce(function (acc, obj) { return acc + parseInt(obj.selling_price) * obj.quantity; }, 0)} )</span>}
                        </h5>
                    </div>
                    {
                        type == "view-all" &&
                        <h5 className='home-carousel-componentheader-viewall'>View all</h5>
                    }
                </div>
                <div className='addon_selected_heading_con'>
                    {
                        addon.filter(ele1 => ele1.heading == heading).map(ele => {
                            return (
                                <h5 className='addon_product_title_heading'>{ele.title} <span className='addon_product_title_quantity'>x {ele.quantity}</span> ,</h5>
                            )
                        })
                    }
                </div>

                {
                    !isMobile &&
                    <div className={`previous${position} customprevious carousel-addon-customprevious`}><BiSolidChevronLeft size="30" /></div>
                }
                <Swiper
                    effect="fade"
                    onInit={(ev) => {
                        set_my_swiper(ev)
                    }}
                    spaceBetween={10}
                    breakpoints={{
                        200: {
                            slidesPerView: 2.3,
                        },
                        700: {
                            slidesPerView: 3,
                        },
                        750: {
                            slidesPerView: 4,
                        },
                        1000: {
                            slidesPerView: 5,
                        },
                        1200: {
                            slidesPerView: 5,
                        },
                        1400: {
                            slidesPerView: 5,
                        },
                        1600: {
                            slidesPerView: 5,
                        },
                        1800: {
                            slidesPerView: 5,
                        },
                    }}
                    onSlideChange={(e) => slidechange(e)}
                    navigation={{
                        nextEl: `.next${position}`,
                        prevEl: `.previous${position}`,
                    }}
                    freeMode={true}
                    modules={[Navigation, FreeMode, Pagination, Scrollbar, A11y]}
                    className={`mycategorySwiper${position}`}
                >

                    {
                        carouselCompData.map((ele, index1) => {
                            return (
                                <>
                                    <SwiperSlide
                                        onClick={() => {
                                            my_swiper.slideToClickedSlide(index1)
                                        }}
                                        key={index1}>
                                        <HomeProductCard2 item={ele} heading={heading} type={type} link={link} />
                                        {/* <div className='allcategory-title'><span>{ele.category_name}</span></div> */}
                                    </SwiperSlide>
                                </>

                            )
                        })
                    }

                </Swiper>
                {
                    !isMobile &&
                    <div className={`next${position} ${link == 'product-detail' ? "customnextdetail" : "customnext carousel-addon-customnext"}`}><BiSolidChevronRight size="30" /></div>
                }

            </Col>


        </Row>
    );
};

export default HomeCarouselCardComponentDup;