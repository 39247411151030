import { motion, animate } from 'framer-motion';
import { useEffect, useRef } from "react";

function Progressbar({ value, star }) {
    const progressTextRef = useRef(null);
    useEffect(() => {
        const progressText = progressTextRef.current?.textContent;
        if (progressText != null) {
            animate(parseInt(progressText), value, {
                duration: 2,
                onUpdate: (cv) => {
                    progressTextRef.current.textContent = cv.toFixed(0)
                }
            });
        }
    }, [value]);
    return (
        <div className="progressbar-container">
            <div className="progressbar-startext">
                <div>{star} <span>star</span></div>
            </div>
            <div className="progressbar">
                <motion.div
                    className="bar"
                    animate={{
                        width: `${value}%`
                    }}
                    transition={{
                        duration: 2
                    }}
                />
            </div>
            <div className="progressbar-text-container">
                <div ref={progressTextRef}>0</div> <div >%</div>
            </div>
        </div>
    );
}

export default Progressbar;