import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import { useMediaQuery } from "react-responsive";
import 'swiper/css/pagination';
import { NavLink } from 'react-router-dom';
import 'swiper/css/scrollbar';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import logo from '../../images/bg_image.webp';
import { useSelector } from 'react-redux';
const MultipleTabComponenet = ({ loopindex, carouselCompData,position, type, link }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const carusolesmall = useSelector(state => state.masterdata.carusolesmall);
  const carusolemedium = useSelector(state => state.masterdata.carusolemedium);
  const carusolelarge = useSelector(state => state.masterdata.carusolelarge);
  const carusoleextralarge = useSelector(state => state.masterdata.carusoleextralarge);
  return (
    <>


      <div className='ourproducts-header-main'>


        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={10}
          effect="cards"
  className="qqqqqq"
          navigation={{
            nextEl: `.next${position}`,
            prevEl: `.previous${position}`,
          }}
          breakpoints={{
            200: {
              slidesPerView: carusolesmall,
          },
          700: {
              slidesPerView: carusolemedium,
          },
          750: {
              slidesPerView: carusolelarge,
          },
          1000: {
              slidesPerView: carusoleextralarge,
          },
          1200: {
              slidesPerView: carusoleextralarge,
          },
          1400: {
              slidesPerView: carusoleextralarge,
          },
          1600: {
              slidesPerView: carusoleextralarge,
          },
          1800: {
              slidesPerView: carusoleextralarge,
          },
          }}
          // className={`mycategorySwiper${1}`}

        >
          <div>
            {
              carouselCompData.sectionResult.map((ele,index) => {
                if (ele.variantList.length > 0) {
                  var detail_url = `${ele.slug}?variant=${ele.variantList[0].variantid}`;
                } else {
                  var detail_url = ele.slug;
                }
                return (
                    <SwiperSlide key={index} className='ssss'>
                      <NavLink to={`/product/${detail_url}`} className={"text-decoration-none"}>
                        <div className='ourproducts-main-cards'>
                        <div className='ourproducts-swiper'>
                          <div className='ourproducts-swiper-image-main-div'><img referrerPolicy="no-referrer"  className='ourproducts-swiper-image' src={localStorage.getItem("cdn") + ele.product_images[0]}
                           onError={e => {
                            e.currentTarget.src = logo
                          }}/></div>
                       <div className='ourproducts-title-main'>
                          <h3 className='ourproducts-swiper-image-title'>{ele.product_title}</h3>
                          <div className='ourproducts-swiper-price'>{localStorage.getItem("currency") + ele.selling_price}</div>
                          </div>
                        </div>
                        </div>
                     
                      </NavLink>
                    </SwiperSlide>
                )
              })
            }
          </div>

        </Swiper>
        {
          !isMobile &&
          <div key={loopindex}>
            <div className={` customprevious ourprev previous${position}`}><BiSolidChevronLeft  /></div>
            <div className={` customnext ournext next${position} `}><BiSolidChevronRight  /></div>
          </div>

        }

      </div>




    </>
  );
};

export default MultipleTabComponenet;