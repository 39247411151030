import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import logo from '../../images/bg_image.webp';
const Brand4 = ({carouselCompData}) => {
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const brands = [
        {
            image: "https://www.ourshopee.com/ourshopee-img/ourshopee_homebanners/78146330Home%20Appliances.jpg",

        },
        {
            image: "https://www.ourshopee.com/ourshopee-img/ourshopee_homebanners/343459551Food_Preparation.jpg",

        },
        {
            image: "https://www.ourshopee.com/ourshopee-img/ourshopee_homebanners/78146330Home%20Appliances.jpg",

        },
        {
            image: "https://www.ourshopee.com/ourshopee-img/ourshopee_homebanners/78146330Home%20Appliances.jpg",

        },
    ]
    // const [our1, our2, our3, our4] = brands;
    return (
        <div>
            <Container fluid>
                <Row>
                    {
                        carouselCompData.sectionResult.sort((a, b) => a.id - b.id).slice(0,4).map(ele => {
                            return (
                                <Col lg={3} md={6} sm={6} xs={12} className='brands-col-maindiv'>
                                     <NavLink to={ele.url}>

                                    <div className='single-banner'>
                                        {
                                            !isMobile ?
                                            <img  referrerPolicy="no-referrer" className='brand-images' src={ele.image}
                                            onError={e => {
                                                e.currentTarget.src = logo
                                              }}
                                            ></img>:
                                            <img referrerPolicy="no-referrer" className='brand-images' src={ele.mobile_image} 
                                            onError={e => {
                                                e.currentTarget.src = logo
                                              }}
                                            ></img>

                                        }
                                     
                                    </div>
                                    </NavLink>
                                </Col>
                            )
                        })
                    }

                </Row>
            </Container>
        </div>
    );
};


export default Brand4;








// {
    //     image:"https://plus.unsplash.com/premium_photo-1675767934632-9890c13c8aca?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8aG9yaXpvbnRhbCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D",
        
    // },
    // {
    //     image:"https://images.unsplash.com/photo-1498550744921-75f79806b8a7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG9yaXpvbnRhbCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D",
    
    // },
    // {
    //     image:"https://images.unsplash.com/photo-1540270776932-e72e7c2d11cd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8aG9yaXpvbnRhbCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D",
    
    // },
    // {
    //     image:"https://images.unsplash.com/photo-1433838552652-f9a46b332c40?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGhvcml6b250YWwlMjB3YWxscGFwZXJ8ZW58MHx8MHx8fDA%3D",
        
    // }