import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import { useMediaQuery } from "react-responsive";
import { HomeProductCard } from "../common";
import { BiSolidChevronRight, BiSolidChevronLeft } from "react-icons/bi";
import logo from '../../images/bg_image.webp';
const HomeComponenet4 = ({ data, classname,btn }) => {

    const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

    const [activeIndex, setactiveIndex] = React.useState(0);

    const handleSelect = (selectedIndex, e) => {
        setactiveIndex(selectedIndex);
    };

    return (
        <div className="home-component4">
            {data.length > 0 && (
                <Row >
                    <Col lg={5}>

                        <Carousel
                            fade
                            controls={true}
                            interval={5000}
                            onSelect={handleSelect}
                            
                            indicators={false}
                            nextIcon={<div className={`customnext-brands`}><BiSolidChevronRight size="30" /></div>}
                            prevIcon={<div className={`customprevious-brands`}><BiSolidChevronLeft size="30" /></div>}
                        
                           className="eight-card-comp"
                        >

                            {data.map((each, i) => (
                                <Carousel.Item key={i} className="home-component4-carousel  eight-card-item">
                                    <NavLink to={each.url}>

                                        <img 
                                        referrerPolicy="no-referrer"
                                        src={each["desktop-image"]}
                                            alt=""
                                            className={`brand-des ${classname}`}
                                            onError={e => {
                                                e.currentTarget.src = logo
                                              }}
                                        />
                                    </NavLink>

                                </Carousel.Item>

                            ))}
                        </Carousel>

                    </Col>

                    <Col lg={7} >
                        <Row className="home-card-row">
                            {
                                data[activeIndex].productlist.slice(0,8).map((ele, index) => {
                                    return (
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={4} xs={6} key={index} className="home-card-col">
                                            <HomeProductCard type={"homecomponent4"} item={ele} classname={classname} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>


                    </Col>
                </Row>


            )}

        </div>

    );
};

export default HomeComponenet4;
