import React from "react";
import { Col, Row } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { HomeProductCard } from "../common";
import { useMediaQuery } from 'react-responsive';
import logo from '../../images/bg_image.webp';
const MobileOnecard8banner = ({ data, classname }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    return (
        <div>
            {
                data.map(each => {
                    return (
                        <Row>
                            <Col lg={12}>
                            <NavLink to={each.url}>
                                {
                                     
                                    <img
                                    referrerPolicy="no-referrer"
                                 
                                    src={each["mobile-image"]}
                                    alt=""
                                  className="eightcardbanner"
                                    onError={e => {
                                        e.currentTarget.src = logo
                                    }}
                                />
                                }
                              
                                           </NavLink>

                            </Col>
                            <Row lg={12} md={12} sm={12} xs={12} className="eightbanner-images">
{


                            each.productlist.slice(0,6).map((ele, index) => {
                                    return (
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={4} xs={6} key={index} className="home-card-col">
                                            <HomeProductCard type={"homecomponent4"} item={ele} classname={classname} />
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                          
                        </Row>
                    )
                })
            }


        </div>
    );
};
export default MobileOnecard8banner;