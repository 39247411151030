
import React, { useState, useEffect } from 'react';
import { FaPlus } from "react-icons/fa6";
import { Row } from 'react-bootstrap'
import { RiShoppingCart2Fill } from "react-icons/ri";
import logo from '../../images/bg_image.webp';

const CrosSellList = ({ data }) => {
    const [selectedItems, setSelectedItems] = useState(data.slice());
    const [totalPrice, setTotalPrice] = useState(0);
    const [activeCheckboxCount, setActiveCheckboxCount] = useState(data.length);

    useEffect(() => {
        setActiveCheckboxCount(selectedItems.length);
        calculateTotalPrice(selectedItems);
    }, [selectedItems]);

    const handleItemClick = (index) => {
        const selectedItem = data[index];

        let updatedItems = [...selectedItems];
        if (updatedItems.includes(selectedItem)) {
            updatedItems = updatedItems.filter(item => item !== selectedItem);
        } else {
            updatedItems.push(selectedItem);
        }

        setSelectedItems(updatedItems);
    };

    const calculateTotalPrice = (items) => {
        let updatedTotalPrice = 0;

        items.forEach(item => {
            updatedTotalPrice += parseInt(item.selling_price, 10);
        });

        setTotalPrice(updatedTotalPrice);
    };

    return (
        <div>

            <div className='productbought-main-title-content'>


                <div className='productsbought'>
                    {data.map((item, index) => (
                        <div className='productsbought-icn-and-details'>
                            <div key={index} className='productsbought-sub-main'>


                                <div className={`carousel-componet-card  products-bought-sub-div`}>

                                    <div className={`home-product-card productbought-djhf  `}>
                                        <div className={`home_product_image  productbought-image-main-div`}>

                                            <img referrerPolicy="no-referrer" className=' productbought-images-image-imagess  ' src={item.product_images}
                                            onError={e => {
                                                e.currentTarget.src = logo
                                              }}
                                            />

                                            <input className='productbought-image-checkout'
                                                type="checkbox"
                                                checked={selectedItems.includes(item)}
                                                onChange={() => handleItemClick(index)}
                                            />

                                        </div>




                                        <h3 className='home-product-card-title'>{item.title}</h3>





                                        <div className="product-price"><div className="product-price product-price-revamp"><div className="Paragraph-16-M--Semibold">{localStorage.getItem("currency") + item.selling_price}</div>

                                        </div>
                                        </div>

                                    </div>
                                    <div>

                                    </div>
                                </div>

                              
                            </div>
                            <div className='product-plus-icon-div'>
                                {

                                    (data.length != index + 1) && <FaPlus className='product-bought-icon' />
                                }
                            </div>
                        </div>
                    ))}
                </div>

                <div className='product-bought-output'>



                    <div className="product-price product-boudht-price-"><div className="product-price product-price-revamp"><div className="Paragraph-16-M--Semibold"> <span className='product-boudht-price-title-div'>  Total Price:</span><span className='product-boudht-price-div'> {localStorage.getItem("currency") + totalPrice}</span></div></div></div>

                    <div className='product-detail-buttongroup product-detal-cart_btn '>

                        <div className={`product-detail-button cart-btn product-bought-button `}


                        >


                            <RiShoppingCart2Fill className="product-detail-btn-icon" />
                            Add all {activeCheckboxCount} to cart






                        </div>



                    </div>


                    {/* Your checkout button or form can go here */}

                </div>
            </div>
        </div>
    );
};





export default CrosSellList;
