/*
 ** Author: Santosh Kumar Dash
 ** Author URL: http://santoshdash.epizy.com/
 ** Github URL: https://github.com/quintuslabs/fashion-cube
 */

import { combineReducers } from "redux";
// import login from "./LoginReducer";
// import register from "./RegisterReducer";
import masterdata from "./MasterReducer";
import addressdata from "./AddressReducer";
import cartdetail from "./CartReducer";
import catlogdetail from "./SearchReducer";
import productdetail from "./ProductReducer";
import orderdetail from "./OrderReducer";
import authdetail from "./AuthReducer";
// import product from "./productReducer";
// import variant from "./variantsReducer";
// import cart from "./cartReducer";
// import checkout from './checkoutReducer'
// import filter from './filterReducer'

export default combineReducers({
  masterdata,
  addressdata,
  cartdetail,
  catlogdetail,
  productdetail,
  authdetail,
  orderdetail
});
