import React, { useEffect, useContext, useState } from 'react';
import { AddressList, MyAccountComp, CartList, SubscriptionMobile, MobileHeader1, CHeckoutPaymentMethod, CHeckoutAddress, AddressForm, BillingAddressForm, Loder, ReactToast } from '../components/common';

import { Container, Row, Col } from 'react-bootstrap';
import { StateContext } from '../components/context/ManageStates';
import { useMediaQuery } from 'react-responsive';
import '../styles/5 - components/_subscription.scss'
import {  useSearchParams } from 'react-router-dom';
import AddressAction from "../redux/actions/AddressAction"
import MasterAction from '../redux/actions/MasterAction';
import MyAccountAction from '../redux/actions/MyAccountAction';
import CartAction from '../redux/actions/CartAction';
import OrderAction from '../redux/actions/OrderAction';
import { useSelector,useDispatch } from 'react-redux';
const Subscription = ({ customclass }) => {
    
    const dispatch = useDispatch()
    const { getAddressList } = AddressAction();
    const { getPaymentMethodlist } = OrderAction();
    const { getProfileData } = MasterAction();
    const { getSubscriptionlist } = MyAccountAction();
    const { saveSubscriptionlist } = MyAccountAction();
    const [searchParams, setSearchParams] = useSearchParams();
    const { addToCart } = CartAction(searchParams);
    const openform = useSelector(state => state.addressdata.openform);
    const subscription = useSelector(state => state.masterdata.subscription);
    
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    useEffect(() => {
        {
           
            dispatch({
                type: 'setAddressopen', payload:(1)
            })
            dispatch(getAddressList())
            dispatch(getPaymentMethodlist())
            dispatch(getProfileData())
            dispatch(getSubscriptionlist())
        }
    }, [])


    const addToCartclick = (subscriptionid,type) => {


        dispatch(addToCart(subscriptionid, type))
    }
    const savesubscription = (subscriptionid) => {


        dispatch(saveSubscriptionlist(subscriptionid))
    }
    // const { addressList } = useContext(StateContext)
    return (
        <>
            <div className={`containerq mt-4 mb-5 ${customclass}`}>
                {
                    subscription && subscription.length > 0 ?
                    subscription.map((ele) => {
                        return (
                            <Col lg={4} className='column'>
                                <div className="pricing-card basic">
                                    <div className="pricing-header">
                                        <span className="plan-title">{ele.name}</span>
                                        <div className="price-circle">
                                            <span className="price-title">
                                                <small> &#8377;</small><span>{ele.price}</span>
                                            </span>
                                            <span className="info">/{ele.type}</span>
                                        </div>
                                    </div>
                                    <div className="badge-box">
                                    </div>
                                    <ul>

                                        <li>
                                            <strong>{ele.discount}% Discount</strong> on All Products
                                        </li>
                                    </ul>
                                    <div className="buy-button-box">
                                        {customclass ?
                                            <a href="#" onClick={() => addToCartclick(ele.subscriptionid, "subscription")} className="buy-now">ADD ITEM</a>
                                            :
                                            <a href="#" onClick={() => savesubscription(ele.subscriptionid, "subscription")} className="buy-now">BUY NOW</a>}


                                    </div>
                                </div>
                            </Col>
                        )
                    })
                    :<div className='custom-height'></div>
                }
            </div>
        </>
    );
};

export default Subscription;