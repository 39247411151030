import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import MasterAction from "./redux/actions/MasterAction";
import CartAction from "./redux/actions/CartAction";
import AddressAction from "./redux/actions/AddressAction";
import AppRouter from "./routers/AppRouter";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";
import { useCookie } from "./hooks";
import ReactGA from "react-ga4";
import * as XLSX from 'xlsx';
const App = () => {
  const dispatch = useDispatch()
  const {getCookie} = useCookie()
  const { getMasterData, getcategorylistData, getHomepageData,getVendorToken,getStaticPageListdatadata } = MasterAction()
  const { getAddressList } = AddressAction()
  const { getCartList } = CartAction()
  const cookies = new Cookies();
  const vendor_token = useSelector(state => state.masterdata.vendor_token);
  if (localStorage.getItem("ga_id") != null) {
    ReactGA.initialize(localStorage.getItem("ga_id"));
  }
  
  useEffect(() => {
    if (vendor_token != '') {
      getCookie()
      dispatch(getMasterData())
      dispatch(getcategorylistData())
      dispatch(getHomepageData())
      dispatch(getStaticPageListdatadata())
      dispatch(getCartList())
      if (cookies.get("jwt_token") != undefined) {
        dispatch(getAddressList())
      };
    }
  }, [vendor_token])

  useEffect(() => {
    document.body.style.setProperty('--header-bg', localStorage.getItem("header_bg"))
    document.body.style.setProperty('--primary_color', localStorage.getItem("primary_color"))
    document.body.style.setProperty('--header-text-color', localStorage.getItem("header_text_color"))
    document.body.style.setProperty('--nav-bg', localStorage.getItem("nav_bg"))
    document.body.style.setProperty('--nav-text-color', localStorage.getItem("nav_text_color"))
    document.body.style.setProperty('--app-aspect-ratio', localStorage.getItem("app_aspect_ratio"))
    if (localStorage.getItem("qwerty") === null) {
      dispatch(getVendorToken())
    } else {
      dispatch({ type: 'setvendor_token', payload: localStorage.getItem("qwerty") })
    }
  }, [])

  // useEffect(() => {
  //   const fetchExcelFile = async () => {
  //     try {
  //       const response = await fetch('./images/translanguage.xlsx');
  //       const blob = await response.blob();

  //       const reader = new FileReader();

  //       reader.onload = (e) => {
  //         const data = new Uint8Array(e.target.result);
  //         const workbook = XLSX.read(data, { type: 'array' });

  //         const jsonData = convertToJSON(workbook);
  //         dispatch({
  //           type: 'setlangdata',
  //           payload: jsonData.Web_Translate
  //         });
  //       };

  //       reader.readAsArrayBuffer(blob);
  //     } catch (error) {
  //     }
  //   };

  //   fetchExcelFile();

  //   const convertToJSON = (workbook) => {
  //     const result = {};

  //     workbook.SheetNames.forEach((sheetName) => {
  //       const sheet = workbook.Sheets[sheetName];
  //       result[sheetName] = XLSX.utils.sheet_to_json(sheet);
  //     });

  //     return result;
  //   };
  // }, []);


  return (
    <>
      <Helmet>
        <title>{localStorage.getItem("title") - localStorage.getItem("domain_title")}</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
      </Helmet>
      {
        localStorage.getItem("qwerty") != null && <AppRouter />
      }
    </> 

  );
};

export default App;
