import React, { useContext } from 'react';
import { Header, MobileHeader, BottomTabs } from '../common';
import { useMediaQuery } from 'react-responsive';
import { ReactToast } from '../common';
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';

const Layout2 = ({ children }) => {
    var location = useLocation();
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const productslug = useSelector(state => state.masterdata.productslug);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()
   

    var navigate = useNavigate();
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });

    React.useEffect(() => {
        if (authStatus) {
            dispatch({
                type: 'setauthStatus',
                payload: true
            });
            if (searchParams.get("redirect_url") == 'cart') {
                return navigate('/cart')
            }  if (searchParams.get("redirect_url") == 'product') {
       
                return navigate(`/product/${productslug}`)
            } else {
                return navigate('/')
            }
        }
    }, [authStatus])

    return (
        <>
            {
                !authStatus &&
                <div>
                    {/* {
                        loaderstatus &&
                        <div className='main-backdrop main-backdrop-extra'></div>
                    } */}
                    {
                        !isMobile ?
                            <>
                                <Header />
                            </>
                            :
                            <>
                                <MobileHeader />
                                {location.pathname == '/' && <BottomTabs />}
                            </>
                    }
                    {children}
                    <ReactToast />
                    {/* <Loder /> */}
                </div>
            }
        </>



    )
}
export default Layout2;