const initialState = {
  productDetailData: [],
  prodDetailBottomSection: [],
  addon:[],
  sel2:[],
  quantity:1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'subcategory_data':
      return {
        ...state,
        productcatData: action.payload
      };
    case 'setproductDetailData':
      return {
        ...state,
        productDetailData: action.payload
      };
    case 'setaddon':
      return {
        ...state,
        addon: action.payload
      };
    case 'setquantity':
      return {
        ...state,
        quantity: action.payload
      };
    case 'setprodDetailBottomSection':
      return {
        ...state,
        prodDetailBottomSection: action.payload
      };
    case 'setsel2':
      return {
        ...state,
        sel2: action.payload
      };
    default:
      return state;
  }
};
