import React from 'react';

import { BsFillCheckCircleFill, BsChevronRight } from "react-icons/bs";
import { FeedbackForm } from '../common';
import logo from '../../images/bg_image.webp';
import { NavLink } from 'react-router-dom';
const SuccessOrder = ({ orderDetail,orderno }) => {
    return (
        <div>
            <div className='succussMsgBody'>
                <div className='successIconHead'><img className='successIcon' src='https://media.tenor.com/0AVbKGY_MxMAAAAM/check-mark-verified.gif'
                   onError={e => {
                    e.currentTarget.src = logo
                  }}></img></div>
                <div className='successMsg'>Wooho! Order Placed</div>


            </div>
            <div className='order-success-title'>your Order : <span className='order-success-title1'>#{orderno.orderno
            }</span></div>

            <div className='successOrderProducts'>

                <div className='successItems'>
                    {
                        orderDetail ? (
                            orderDetail.map((ele, ind) => (


                            <NavLink to={`/product/${ele.product_detail[0].slug}`}>
                                <img
                                 referrerPolicy="no-referrer" 
                                    className='successImg'
                                    src={localStorage.getItem("cdn") + ele.product_detail[0].product_images[0]}
                                    key={ind}
                                    onError={e => {
                                        e.currentTarget.src = logo
                                      }}
                                ></img>
                            </NavLink>
                                



                            ))
                        ) : (
                            <p>No product details available</p>
                        )
                    }

                    {/* {
                       orderDetail.product_detail.map((ele,ind) => {
                            return (
                                // <div key={ind}><img className='successImg' src={ele.order_type == 'subscription' ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3DboFFQ04BBxPIavMs86F-OP5LPfWxoCYjg&usqp=CAU" :ele.image}></img></div>
                               
                            //    {
                            //     ele.product_detail.map((item)=>{
                            //         return()
                            //     })
                            //    }
                               
                                <img className='successImg' src=
                                {localStorage.getItem("cdn") + ele.product_images[0]}
                            ></img>
                           
                                )
                        })

                    } */}

                </div>
                <div className='successOrdersButton'>
                    <NavLink to={"/my-orders"} className="text-decoration-none1">
                        <div> View my Orders</div>
                    </NavLink>
                    <div className='successOrdersButtonIcon'><BsChevronRight /></div>

                </div>
            </div>

            <FeedbackForm />

        </div>
    );
};

export default SuccessOrder;