import React ,{useState,useEffect} from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { InputBox } from '../components/common';
import { Link } from 'react-router-dom';
import logo from '../images/2.png';
import { auth } from '../components/firebase/Setup';
import validate from "../validations/FormValidationRules";
import useFormvalidation from '../validations/FormValidation'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
// import { useAuth } from '../hooks';
import OrderAction from '../redux/actions/OrderAction';
import AuthAction from '../redux/actions/AuthAction';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
const BuisnessRegister = () => {
  const dispatch =useDispatch();
  const [currentStep, setcurrentStep] = useState(0);
  const [buttonstatus, setButtonstatus] = useState(false);
  const [user, setuser] = useState(null);
  const verification_status = useSelector(state => state.orderdetail.verification_status);
    const [formValues, setFormValues] = React.useState({
        name: "",
        phone: "",
        mailid: "",
        password: "",
        gst: "",
        cin: "",
        address: "", 
        pincode: "",
        city: "",
        state: "",
        category: 2
    });

    const { SignUp } = AuthAction();

    const { checkemail } = OrderAction();
    const { values, errors, handleChange,setErrors, handleSubmit } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );
    async function awer() {
        if (currentStep == 0) {
            setButtonstatus(true)
            const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {});
            const phoneNumberWithCode = `+91${values.phone}`;
            const confirmation = await signInWithPhoneNumber(auth, phoneNumberWithCode, recaptchaVerifier);
            setcurrentStep(1)
            setuser(confirmation)
            setButtonstatus(false)
        } else {
            if (user) {
                try {
                    var confirmationResult = await user.confirm(values.otp);
                    if (confirmationResult) {
                        dispatch(SignUp(values, setErrors, setcurrentStep))
                        // setOtpVerified(true); 
                        // setEnter(false)
                    } else {
                        // setOtpVerified(false); 
                        // setEnter(true)
                        alert("OTP not verified");
                    }
                } catch (err) {
                    if (err.code === "auth/invalid-verification-code") {
                        setErrors({
                            otp: "Please enter a valid OTP."
                        })
                        // Handle invalid OTP
                    }
                }


            }
        }
    }
// useEffect (()=>{
//     awer()
// })

    useEffect(() => {
        if (verification_status != undefined) {
            if (Object.keys(verification_status).length > 0) {
                setErrors({
                    phone: verification_status.message
                })
            } else {
                awer()
            }

        }

    }, [verification_status])
    async function handleNext() {
        var input_data = {
            email: values.mailid,
            phone: values.phone
        }
        dispatch(checkemail(input_data))
        // if (currentStep == 1) {
        //     awer()
        // }



    }

    return (
        <Container>
                <Helmet>
                                <title>{localStorage.getItem("title") - "BuisnessRegister"}</title>


                            </Helmet>
            <div className="buisness-register-container">
                <div className="register-wrap-container">
                    <Row>
                        <Col lg={6}>
                            <div className="buisnessregitser-left-side">
                                <h4 className="mb-4">Account Details </h4>
                                <Row>
                                    <Col lg={6}>
                                        <InputBox label={"Name"} id={"name"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    {
                                        localStorage.getItem("verification_method") == 1 ?
                                            <Col lg={6}>
                                                <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
                                            </Col> :
                                            <Col lg={6}>
                                                <InputBox label={"Email"} id={"mailid"} handleChange={handleChange} errors={errors} />
                                            </Col>
                                    }

 
                                    <Col lg={6}>
                                        <InputBox label={"Password"} id={"password"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    {
                                currentStep == 1 &&
                                <Col lg={6}>
                                    <InputBox label={"Enter Otp"} id={"otp"} handleChange={handleChange} errors={errors} />
                                </Col>


                            }

                            {
                                currentStep != 1 &&
                                <Col lg={12} className='captcha-div'>

                                    <div id="recaptcha"></div>
                                </Col>
                            }
                                </Row>

                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="buisnessregitser-right-side">
                                <h4 className="mb-4">Buisness Details</h4>
                                <Row>
                                {
                                        localStorage.getItem("verification_method") != 1 ?
                                            <Col lg={6}>
                                                <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
                                            </Col> :
                                            <Col lg={6}>
                                                <InputBox label={"Email"} id={"mailid"} handleChange={handleChange} errors={errors} />
                                            </Col>
                                    }
                                    <Col lg={6}>
                                        <InputBox label={"GSTIN Number"} id={"gst"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    <Col lg={6}>
                                        <InputBox label={"CIN Number"} id={"cin"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    <Col lg={6}>
                                        <InputBox label={"Street Address"} id={"address"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    <Col lg={6}>
                                        <InputBox label={"City"} id={"city"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    <Col lg={6}>
                                        <InputBox label={"Postal Code"} id={"pincode"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    <Col lg={6}>
                                        <InputBox label={"State"} id={"state"} handleChange={handleChange} errors={errors} />
                                    </Col>

                                </Row>

                            </div>
                        </Col>


                    
                        <div className={`product-detail-buttongroup normal-register-btn ${buttonstatus ? "disable" : " "}`} >
                            <div className={`product-detail-button`} onClick={handleSubmit}>

                                {currentStep == 0 ? "Get Otp" : "Verify Otp"}


                            </div>
                        </div>

                        <div className='signupsec'>
                            <span className='DontAccount'>Already have an account, please</span>
                            <Link to="/login" className='signUpNavLink text-underline-none'>Sign In</Link>
                        </div>
                    </Row>


                </div>

            </div>
        </Container>



    );
};

export default BuisnessRegister;