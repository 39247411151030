import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BsFillCheckCircleFill,BsXOctagon } from "react-icons/bs";
import { NavLink } from "react-router-dom";


export const notify = (type,text,showView,redirect_url) =>
    toast(
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <div style={{ display: "flex", marginTop: "2px" }}>
                {
                    type == 'warning' ?
                    <BsXOctagon color={"red"} style={{ marginTop: "3px", marginRight: "6px" }} />
                    :
                    <BsFillCheckCircleFill color={"green"} style={{ marginTop: "3px", marginRight: "6px" }} />
                }
               
                <div style={{ fontWeight: "bold", color: "#000" }}>{text}</div>
            </div>

            {showView && <NavLink to={redirect_url} style={{textDecoration:"none"}} className="toast-view-btn">View</NavLink>}
        </div>
        , {
            progress: undefined,
            theme: "light",
        });


export default function ReactToast() {

    return (
        <div>
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                className="my-custom-toast"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                closeButton={false}
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}
